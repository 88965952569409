import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Config } from '~/common/Config';
import { Env, isProduction } from '~/common/Env';
// Initialize Firebase
var analytics;
if (typeof window !== 'undefined') {
  var app = initializeApp(Config.firebaseConfig(isProduction() ? Env.Prod : Env.QA));
  analytics = getAnalytics(app);
}
export var firebaseAnalyticsProvider = function firebaseAnalyticsProvider() {
  function logAnalyticsEvent(action, params) {
    if (!analytics) return;
    logEvent(analytics, action, params);
  }
  function logPageViewEvent(path, params) {
    if (!analytics) return;
    logEvent(analytics, 'page_view', _objectSpread({
      path: path
    }, params));
  }
  return {
    logAnalyticsEvent: logAnalyticsEvent,
    logPageViewEvent: logPageViewEvent
  };
};
export default firebaseAnalyticsProvider;