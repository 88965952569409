import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { createParam } from 'solito';
import { VisualMode } from '../Env';
var useVisualMode = function useVisualMode() {
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var _useParam = useParam('visualMode'),
    _useParam2 = _slicedToArray(_useParam, 1),
    visualMode = _useParam2[0];

  // Ensure 'visualMode' is a valid value before accessing the enum
  var mode = Object.values(VisualMode).includes(visualMode) ? visualMode : VisualMode.Normal;
  return mode;
};
export default useVisualMode;