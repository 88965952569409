import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsEvents } from '../analytics/Analytics';
import { selectBranch } from '../slices/model.slice';
import { selectIsUserLoggedIn, setUser } from '../slices/user.slice';
import { getLocalStorageItem, removeLocalStorageItem } from '../storage/AppWebStorage';
import useAnalytics from './analytics/useAnalytics';
var ALLOWED_DESTINATION_DOMAIN = 'https://phorest.com';
export function useUserMigration() {
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var branch = useSelector(selectBranch);
  var analytics = useAnalytics();
  var dispatch = useDispatch();
  function postMessage(type) {
    var iframe = document.getElementById('legacyFrame');
    if (!iframe) {
      return;
    }
    // @ts-ignore
    iframe.contentWindow.postMessage({
      type: type,
      domain: branch === null || branch === void 0 ? void 0 : branch.domainName
    }, ALLOWED_DESTINATION_DOMAIN);
  }
  function handleMessage(event) {
    var _event$data;
    if ((event === null || event === void 0 || (_event$data = event.data) === null || _event$data === void 0 ? void 0 : _event$data.type) === 'token') {
      var _event$data2;
      if ((event === null || event === void 0 || (_event$data2 = event.data) === null || _event$data2 === void 0 ? void 0 : _event$data2.token) != null) {
        analytics.logAnalyticsEvent(AnalyticsEvents.ExperimentLegacyLoginLoadedFromIFrameLocalStorage);
        dispatch(setUser({
          token: event.data.token
        }));

        // let the iframe know that we logged out
        postMessage('logout');
      }
    }
  }

  // check if there is a legacy token (old OB) in local storage and use it in case
  function findLegacyToken(domainName) {
    var oldOBAuthTokenKey = "".concat(domainName, "-authentication-token");
    var tokenValue = getLocalStorageItem(oldOBAuthTokenKey);
    if (!tokenValue) {
      return;
    }
    analytics.logAnalyticsEvent(AnalyticsEvents.ExperimentLegacyLoginLoadedFromLocalStorage);
    removeLocalStorageItem(oldOBAuthTokenKey);
    return tokenValue;
  }

  // check if there is a persist token (single user redux persist) in local storage and use it in case
  function findPersistToken() {
    var persistedUserKey = 'persist:user';
    var persistedUser = getLocalStorageItem(persistedUserKey);
    if (!persistedUser) {
      return;
    }
    try {
      var jsonObj = JSON.parse(persistedUser);
      if (jsonObj !== null && jsonObj !== void 0 && jsonObj.token) {
        analytics.logAnalyticsEvent(AnalyticsEvents.ExperimentLegacyLoginLoadedFromPeristedUser);
        removeLocalStorageItem(persistedUserKey);
        return jsonObj.token.replace(/['"]+/g, '');
      }
    } catch (_) {
      // no op
    }
    return;
  }
  useEffect(function () {
    // ignore if user is logged in or not on web
    if (isUserLoggedIn || typeof window === 'undefined' || !branch) {
      return;
    }
    var token = findLegacyToken(branch.domainName) || findPersistToken();
    if (token) {
      dispatch(setUser({
        token: token
      }));
    } else {
      // setting up the listeners for iframe message passing
      window.addEventListener('message', handleMessage);
      postMessage('ready');
    }
    return function () {
      window.removeEventListener('message', handleMessage);
    };
  }, [branch]);
}
export default useUserMigration;