import Router from 'next/router';
import { useEffect } from 'react';
import { isWeb } from '~/common/Env';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
export function useTrackPageViews() {
  var analytics = useAnalytics();
  var handleRouteChange = function handleRouteChange(url) {
    analytics.logPageViewEvent(url.toString());
  };
  useEffect(function () {
    if (!isWeb()) return;
    Router.events.on('routeChangeComplete', handleRouteChange);
    return function () {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
}
export default useTrackPageViews;