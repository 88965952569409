function getLocalStorageItem(key) {
  if (storageAvailable()) {
    var _window$localStorage$, _window$localStorage;
    return (_window$localStorage$ = (_window$localStorage = window.localStorage) === null || _window$localStorage === void 0 ? void 0 : _window$localStorage.getItem(key)) !== null && _window$localStorage$ !== void 0 ? _window$localStorage$ : undefined;
  }
  return undefined;
}
function setLocalStorageItem(key, value) {
  if (storageAvailable()) {
    var _window$localStorage2;
    (_window$localStorage2 = window.localStorage) === null || _window$localStorage2 === void 0 || _window$localStorage2.setItem(key, value);
  }
}
function removeLocalStorageItem(key) {
  if (storageAvailable()) {
    var _window$localStorage3;
    (_window$localStorage3 = window.localStorage) === null || _window$localStorage3 === void 0 || _window$localStorage3.removeItem(key);
  }
}
function storageAvailable() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'localStorage';
  var storage;
  try {
    // @ts-ignore
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
export { getLocalStorageItem, setLocalStorageItem, storageAvailable, removeLocalStorageItem };