import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import last from 'lodash/last';
import { runOnWebOnly } from '../Helpers';
import { getSalonCurrency } from '../Localize';
import { CartItemType } from '../slices/cart.slice';
import { ANALYTICS_GA4_ID, FACEBOOK_APP_ID, FACEBOOK_PIXEL_ID } from './Analytics';
import { facebookAnalyticsProvider } from './providers/facebookAnalyticsProvider';
import { firebaseAnalyticsProvider } from './providers/firebaseAnalyticsProvider';
import { googleAnalyticsProvider } from './providers/googleAnalyticsProvider';
export function analyticsLogger(business, branch, customProviders) {
  var defaultParams = {
    business_id: business.id,
    business_name: business.name,
    branch_id: branch.id,
    domain_name: branch.domainName,
    branch_name: branch.name
  };
  var defaultProviders = [
  // Firebase is default analytics engine,
  // used on apps and web to send data to Phorest analytics
  firebaseAnalyticsProvider()];
  runOnWebOnly(function () {
    if (typeof document === 'undefined') return;

    // check GA4 has been setup on the web platform
    if (document.getElementById(ANALYTICS_GA4_ID) != null) {
      defaultProviders.push(googleAnalyticsProvider());
    }
    // check FB Pixel has been setup on the web platform
    if (document.getElementById(FACEBOOK_APP_ID) != null || document.getElementById(FACEBOOK_PIXEL_ID) != null) {
      defaultProviders.push(facebookAnalyticsProvider());
    }
    // on GTM we don't need to setup the providers here
    // as it logs with firebase automatically
  });
  var providers = customProviders !== null && customProviders !== void 0 ? customProviders : defaultProviders;
  var logAnalyticsEvent = function logAnalyticsEvent(action, params) {
    providers.forEach(function (provider) {
      return provider.logAnalyticsEvent(action, _objectSpread(_objectSpread({}, params), defaultParams));
    });
  };
  var logPageViewEvent = function logPageViewEvent(path, params) {
    providers.forEach(function (provider) {
      return provider.logPageViewEvent(path, _objectSpread(_objectSpread({}, params), defaultParams));
    });
  };
  return {
    logAnalyticsEvent: logAnalyticsEvent,
    logPageViewEvent: logPageViewEvent
  };
}

// ############################################################
// Firebase GA4 Events
// https://firebase.google.com/docs/analytics/measure-ecommerce
// ############################################################

// Reservations items

export function analyticsPurchaseEventParams(reservation, numberOfClients) {
  var _getSalonCurrency, _reservation$discount, _reservation$discount2;
  var items = analyticsItemFromReservation(reservation);
  return {
    transaction_id: reservation.id,
    value: reservation.total,
    currency: (_getSalonCurrency = getSalonCurrency()) !== null && _getSalonCurrency !== void 0 ? _getSalonCurrency : '',
    deposit: reservation.deposit,
    discount_type: (_reservation$discount = reservation.discount) === null || _reservation$discount === void 0 ? void 0 : _reservation$discount.type,
    discount_value: (_reservation$discount2 = reservation.discount) === null || _reservation$discount2 === void 0 ? void 0 : _reservation$discount2.value,
    number_of_clients: numberOfClients,
    items: items
  };
}
export function analyticsItemFromReservation(reservation) {
  var items = reservation.appointments.map(function (appointment) {
    return {
      item_id: appointment.id,
      item_name: appointment.service.name,
      item_service_group_id: appointment.serviceGroupId,
      item_type: appointment.serviceGroupId ? 'ServiceGroup' : 'Service',
      price: appointment.price
    };
  });
  return items;
}

// Cart items
export function analyticsCartEventParams(cartItem) {
  var _getSalonCurrency2;
  var items = cartItem.map(analyticsItemFromCartItem);
  var value = items.reduce(function (total, item) {
    return total + item.price;
  }, 0);
  return {
    currency: (_getSalonCurrency2 = getSalonCurrency()) !== null && _getSalonCurrency2 !== void 0 ? _getSalonCurrency2 : '',
    value: value,
    items: items
  };
}
function analyticsItemFromCartItem(cartItem) {
  var _name, _type, _price;
  var name, category, price, type;
  if (cartItem.type === CartItemType.Service) {
    var _service$category;
    var service = cartItem.item;
    name = service.name;
    category = (_service$category = service.category) === null || _service$category === void 0 ? void 0 : _service$category.name;
    price = service.price;
    type = 'Service';
  } else if (cartItem.type === CartItemType.ServiceGroup) {
    var _last, _last2;
    var serviceGroup = cartItem.item;
    name = serviceGroup.name;
    category = (_last = last(cartItem.options)) === null || _last === void 0 || (_last = _last.service) === null || _last === void 0 || (_last = _last.category) === null || _last === void 0 ? void 0 : _last.name;
    price = (_last2 = last(cartItem.options)) === null || _last2 === void 0 ? void 0 : _last2.price;
    type = 'Service Group';
  } else if (cartItem.type === CartItemType.Course) {
    var _course$service$categ;
    var course = cartItem.item;
    name = course.service.name;
    category = (_course$service$categ = course.service.category) === null || _course$service$categ === void 0 ? void 0 : _course$service$categ.name;
    price = course.service.price;
    type = 'Course';
  }
  return {
    item_id: cartItem.item.id,
    item_name: (_name = name) !== null && _name !== void 0 ? _name : '',
    item_category: category,
    item_type: (_type = type) !== null && _type !== void 0 ? _type : '',
    quantity: 1,
    price: (_price = price) !== null && _price !== void 0 ? _price : 0
  };
}
export function getGoogleConversionTrackingData() {
  var _document;
  var rwgTokenCookie = (_document = document) === null || _document === void 0 || (_document = _document.cookie) === null || _document === void 0 ? void 0 : _document.split('; ').find(function (row) {
    return row.startsWith('_rwg_token=');
  });
  if (typeof rwgTokenCookie !== 'undefined') {
    var token = rwgTokenCookie.split('=')[1];
    var type = 'google';
    return {
      type: type,
      token: token
    };
  }
  return {};
}