import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useSelector } from 'react-redux';
import { selectAppVersion } from '~/common/slices/app.slice';
import { selectBranch, selectBusiness } from '~/common/slices/model.slice';
import Sentry from './Sentry';
export var CRITICAL_PAYMENT_ERROR_MESSAGE = 'Critical payment error - ';
function getDefaultTags(business, branch, version) {
  return {
    business_id: business === null || business === void 0 ? void 0 : business.id,
    business_name: business === null || business === void 0 ? void 0 : business.name,
    branch_id: branch === null || branch === void 0 ? void 0 : branch.id,
    domain_name: branch === null || branch === void 0 ? void 0 : branch.domainName,
    branch_name: branch === null || branch === void 0 ? void 0 : branch.name,
    app_version: version
  };
}
export function captureSentryMessage(message, tags, level, business, branch, version) {
  Sentry.captureMessage(message, {
    tags: _objectSpread(_objectSpread({}, tags), getDefaultTags(business, branch, version)),
    level: level !== null && level !== void 0 ? level : 'error'
  });
}
var useSentry = function useSentry() {
  var business = useSelector(selectBusiness);
  var branch = useSelector(selectBranch);
  var version = useSelector(selectAppVersion);
  var captureMessage = function captureMessage(message, tags, level) {
    captureSentryMessage(message, tags, level, business, branch, version);
  };
  var captureCriticalPaymentErrorMessage = function captureCriticalPaymentErrorMessage(message, tags, level) {
    captureSentryMessage("".concat(CRITICAL_PAYMENT_ERROR_MESSAGE).concat(message), tags, level, business, branch, version);
  };
  return {
    captureMessage: captureMessage,
    captureCriticalPaymentErrorMessage: captureCriticalPaymentErrorMessage
  };
};
export default useSentry;