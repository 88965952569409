import React from "react";
var __jsx = React.createElement;
import { NextSeo } from 'next-seo';
import useMetaTags from '~/common/hooks/useMetatags';

// To add a new metatag definition for a page
//
// 1. go to libs/app/src/common/hooks/useMetatags.tsx
// 2. Add a displayName to your Screen like HomePage.displayName = RouteName.Home
// 2. add a new key same as the Component displayName at tags
// 3. add the tags - Can be string values or variables from the router or the state
export function Metatags(_ref) {
  var pageProps = _ref.pageProps;
  var _useMetaTags = useMetaTags(),
    getPageMetaTags = _useMetaTags.getPageMetaTags;
  var _getPageMetaTags = getPageMetaTags(pageProps.Component.displayName),
    title = _getPageMetaTags.title,
    description = _getPageMetaTags.description,
    noindex = _getPageMetaTags.noindex,
    canonical = _getPageMetaTags.canonical,
    _getPageMetaTags$open = _getPageMetaTags.openGraph,
    openGraph = _getPageMetaTags$open === void 0 ? {} : _getPageMetaTags$open;
  return __jsx(NextSeo, {
    title: title,
    description: description,
    noindex: noindex,
    canonical: canonical,
    openGraph: openGraph
  });
}
export default Metatags;

// Typical page example, https://github.com/garmeeh/next-seo

/* <NextSeo
  title="Using More of Config"
  description="This example uses more of the available config options."
  canonical="https://www.canonical.ie/"
  openGraph={{
    url: 'https://www.url.ie/a',
    title: 'Open Graph Title',
    description: 'Open Graph Description',
    images: [
      {
        url: 'https://www.example.ie/og-image-01.jpg',
        width: 800,
        height: 600,
        alt: 'Og Image Alt',
        type: 'image/jpeg',
      },
      {
        url: 'https://www.example.ie/og-image-02.jpg',
        width: 900,
        height: 800,
        alt: 'Og Image Alt Second',
        type: 'image/jpeg',
      },
      { url: 'https://www.example.ie/og-image-03.jpg' },
      { url: 'https://www.example.ie/og-image-04.jpg' },
    ],
    siteName: 'SiteName',
  }}
  twitter={{
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  }}
/> */