import { isBranchOpen } from '~/api/models/ModelFunctions';
var NEON_NIGHTS_NAV_COLOR = '#02023E';
export function removeInvalidBranchesFromBusiness(business, isVip) {
  return {
    id: business.id,
    name: business.name,
    locale: business.locale,
    silo: business.silo,
    branches: business.branches.filter(function (branch) {
      return isBranchOpen(branch, isVip);
    })
  };
}
export function addressFromBranch(branch) {
  var address = branch.address.streetAddress1 ? branch.address.streetAddress1 : '';
  if (branch.address.city) {
    address += address.length > 0 ? ', ' : '';
    address += branch.address.city;
  }
  return address;
}
export function shadeColor(color, percent) {
  if (color === NEON_NIGHTS_NAV_COLOR) {
    return '#1F1565';
  }
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);
  // @ts-ignore
  R = parseInt(R * (100 + percent) / 100);
  // @ts-ignore
  G = parseInt(G * (100 + percent) / 100);
  // @ts-ignore
  B = parseInt(B * (100 + percent) / 100);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);
  return '#' + RR + GG + BB;
}
export function shouldShowBranchSearchBar(numberOfBranches, minBranchesForSearch) {
  return numberOfBranches >= minBranchesForSearch;
}
export function hasOnlyOneBranch(business) {
  return business.branches.length === 1;
}