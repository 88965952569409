import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
var __jsx = React.createElement;
import { useEffect, useRef } from 'react';
import React from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { WEB_CONTAINER_WIDTH } from '~/api/models/Constants';
import { isWeb, VisualMode } from '~/common/Env';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import useVisualMode from '~/common/hooks/useVisualMode';
import { selectIsEmbedded, setIsWelcomeDisplayed } from '~/common/slices/app.slice';
import { RouteName } from '~/navigation/Routes';
import { Container, Theme, themed } from '~/styles/Theme';
import FeedbackFabButton from '../FeedbackFabButton';
import EmbedNavigator from './EmbedNavigator';
import Footer from './Footer';
import DesktopHeader from './header/DesktopHeader';
import MobileHeader from './header/MobileHeader';
import MobileSidebar from './header/MobileSidebar';
var CENTERED_CONTAINER_LIST = [RouteName.Home];
var LANDING_LIST = [RouteName.ReferralsLanding];
var PREVIEW_LIST = [RouteName.ReferralsLandingPreview];
export function PageLayout(_ref) {
  var children = _ref.children,
    pageProps = _ref.pageProps;
  var visualModel = useVisualMode();
  var isEmbedded = useSelector(selectIsEmbedded);
  var displayName = pageProps.Component.displayName;
  var isHomeScreen = CENTERED_CONTAINER_LIST.includes(displayName) && !isEmbedded;
  var isLandingScreen = LANDING_LIST.includes(displayName);
  var isPreviewScreen = PREVIEW_LIST.includes(displayName);
  var footerRef = useRef(null);
  var dispatch = useDispatch();
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  useEffect(function () {
    dispatch(setIsWelcomeDisplayed(true));
  }, []);
  if (visualModel === VisualMode.Screenshot) {
    return __jsx(MainContainer, {
      testID: "mainContainer"
    }, children);
  }
  return __jsx(MainContainer, {
    testID: "mainContainer"
  }, isEmbedded ? __jsx(EmbedNavigator, {
    pageProps: pageProps
  }) : __jsx(View, null, isMobile ? __jsx(MobileHeader, null) : __jsx(DesktopHeader, {
    isLandingScreen: isLandingScreen,
    isPreviewScreen: isPreviewScreen
  })), isHomeScreen ? __jsx(React.Fragment, null, children) : __jsx(CenteredContainer, {
    testID: "CenteredContainer"
  }, __jsx(PageContainer, {
    isEmbedded: isEmbedded
  }, children)), __jsx(FeedbackFabButton, {
    isHomeScreen: isHomeScreen,
    footerRef: footerRef,
    displayName: displayName
  }), __jsx(Footer, {
    footerRef: footerRef
  }), __jsx(MobileSidebar, {
    isLandingScreen: isLandingScreen
  }));
}
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  min-height: 100%\n  ", "\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral05);
});
var CenteredContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-grow: 1\n  flex-shrink: 1\n  flex-basis: 0%\n  background-color: #F6F8F7\n  margin: 0 auto\n  max-width: ", "px\n  width: 100%\n"])), WEB_CONTAINER_WIDTH);
var PageContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex-grow: 1\n  flex-shrink: 1\n  flex-basis: 0%\n  ", "\n"])), function (props) {
  return isWeb() && !props.isEmbedded && 'margin-top: 8px;';
});