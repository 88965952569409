import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import { resetDeveloperOptions, selectDeveloperMode } from '~/common/slices/app.slice';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import { Body1, InfoType } from '~/styles/Theme';
import Localize from '../common/Localize';
import useAnalytics from '../common/hooks/analytics/useAnalytics';
import StatusMessage from './StatusMessage';
var ErrorScreen = function ErrorScreen(props) {
  var analytics = useAnalytics();
  var developerMode = useSelector(selectDeveloperMode);
  var _useRouter = useRouter(),
    replace = _useRouter.replace;
  var dispatch = useDispatch();
  var title = props.title,
    message = props.message,
    actionTitle = props.actionTitle,
    onPress = props.onPress;
  useEffect(function () {
    analytics.logAnalyticsEvent(AnalyticsEvents.ServerErrorScreen, {
      message: message
    });
  }, []);
  function renderChangeDevOptionsButton() {
    return developerMode ? __jsx(Pressable, {
      style: {
        height: 80,
        alignItems: 'center'
      },
      onPress: function onPress() {
        dispatch(resetDeveloperOptions());
      }
    }, __jsx(Body1, null, "Reset custom configs")) : null;
  }
  return __jsx(View, {
    style: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  }, __jsx(StatusMessage, {
    infoType: InfoType.error,
    title: title || Localize('error.title'),
    info: message || Localize('error.subtitle'),
    onPress: onPress,
    testID: "errorMessage",
    buttonText: actionTitle || Localize('error.retry')
  }), __jsx(Pressable, {
    onPress: function onPress() {
      return replace(getRouteUrl(RouteName.Home));
    },
    testID: "homeButton"
  }, __jsx(Body1, null, Localize('bottomTab.home'))), renderChangeDevOptionsButton());
};
export default ErrorScreen;