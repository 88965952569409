import dynamic from 'next/dynamic';
var LottieView = dynamic(function () {
  return import('lottie-react-native');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('lottie-react-native')];
    }
  }
});
export default LottieView;