import { createSlice } from '@reduxjs/toolkit';
import { secureHash } from '../Helpers';
import { resetApp, resetDeveloperOptions, setDeveloperOptions } from './app.slice';
var reducerName = 'user';

// Move to Types

export var initialState = {};
export var userSlice = createSlice({
  name: reducerName,
  initialState: initialState,
  reducers: {
    setUser: function setUser(state, action) {
      state.name = action.payload.name;
      state.token = action.payload.token;
    },
    clearNewCreditCard: function clearNewCreditCard(state) {
      state.newCreditCard = undefined;
    },
    setNewCreditCard: function setNewCreditCard(state, action) {
      state.newCreditCard = action.payload;
    },
    setSelectedCard: function setSelectedCard(state, action) {
      var _action$payload$id, _action$payload;
      state.selectedCard = action.payload;
      state.creditCardHash = secureHash((_action$payload$id = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.id) !== null && _action$payload$id !== void 0 ? _action$payload$id : '');
    },
    setAddNewPaymentMethod: function setAddNewPaymentMethod(state, action) {
      state.addNewPaymentMethod = action.payload;
    },
    resetUser: function resetUser() {
      return initialState;
    }
  },
  extraReducers: function extraReducers(builder) {
    builder.addCase(resetApp, function () {
      return initialState;
    });
    builder.addCase(setDeveloperOptions, function () {
      return initialState;
    });
    builder.addCase(resetDeveloperOptions, function () {
      return initialState;
    });
  }
});
var _userSlice$actions = userSlice.actions,
  setUser = _userSlice$actions.setUser,
  clearNewCreditCard = _userSlice$actions.clearNewCreditCard,
  setNewCreditCard = _userSlice$actions.setNewCreditCard,
  resetUser = _userSlice$actions.resetUser,
  setSelectedCard = _userSlice$actions.setSelectedCard,
  setAddNewPaymentMethod = _userSlice$actions.setAddNewPaymentMethod;
export { setUser, clearNewCreditCard, setNewCreditCard, resetUser, setSelectedCard, setAddNewPaymentMethod };
export var selectName = function selectName(state) {
  return state.user.name;
};
export var selectToken = function selectToken(state) {
  return state.user.token;
};
export var selectIsUserLoggedIn = function selectIsUserLoggedIn(state) {
  return state.user.token != null;
};
export var selectCreditCardHash = function selectCreditCardHash(state) {
  return state.user.creditCardHash;
};
export var selectNewCreditCard = function selectNewCreditCard(state) {
  return state.user.newCreditCard;
};
export var selectSelectedCard = function selectSelectedCard(state) {
  return state.user.selectedCard;
};
export var selectAddNewPaymentMethod = function selectAddNewPaymentMethod(state) {
  return state.user.addNewPaymentMethod;
};
export default userSlice.reducer;