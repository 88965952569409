import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { decode } from 'js-base64';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createParam } from 'solito';
import { selectIsVip } from '../slices/app.slice';
function findVipLink(metadata) {
  var metadataObj = {};
  if (metadata) {
    try {
      metadataObj = JSON.parse(decode(metadata));
    } catch (e) {
      return false;
    }
  }
  return metadataObj.vip;
}
var useVipBooking = function useVipBooking() {
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var _useParam = useParam('md'),
    _useParam2 = _slicedToArray(_useParam, 1),
    metadata = _useParam2[0];

  // vip link saved into redux
  var isVip = useSelector(selectIsVip);
  // vip link from url metadata
  var _useState = useState(false),
    hasVipLink = _useState[0],
    setVipLink = _useState[1];
  useEffect(function () {
    if (metadata) {
      setVipLink(findVipLink(metadata) === true);
    }
  }, [metadata]);
  return isVip || hasVipLink;
};
export default useVipBooking;