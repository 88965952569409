import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint @typescript-eslint/no-explicit-any: off */
import isEmpty from 'lodash/isEmpty';
import { BasketStatusEnum, CourseUnitType, DiscountType, PaymentTypeEnum } from './BookingTypes';
import { padWithLeadingZeroes } from './ModelFunctions';
var Mapper = {
  /* Mapping Staff */allStaff: function allStaff(payload) {
    var staffs = payload.staffs,
      linked = payload.linked;
    return {
      staffs: staffs.map(function (item) {
        return Mapper.staff(item, linked);
      })
    };
  },
  staff: function staff(_staff, linked) {
    var category = {
      id: _staff.staff_category_id
    };
    if (linked) {
      var linkedCategory = linked.staff_categories.find(function (item) {
        return item.id === _staff.staff_category_id;
      });
      if (linkedCategory) {
        category.name = linkedCategory.name;
      }
    }
    return {
      id: _staff.id,
      firstName: _staff.first_name,
      lastName: _staff.last_name,
      gender: _staff.gender,
      isArchived: _staff.archived,
      disqualifiedServiceIds: _staff.disqualified_service_ids !== undefined ? _staff.disqualified_service_ids : [],
      onlineProfile: _staff.online_profile,
      imageUrl100: _staff.image_100_url,
      imageUrl500: _staff.image_500_url,
      hiddenFromOnlineBookings: _staff.hide_from_online_bookings !== undefined ? _staff.hide_from_online_bookings : false,
      category: category
    };
  },
  staffCategories: function staffCategories(_staffCategories) {
    return {
      staffCategories: _staffCategories.staff_categories.map(function (item) {
        return Mapper.staffCategory(item);
      })
    };
  },
  staffCategory: function staffCategory(_staffCategory) {
    return _staffCategory;
  },
  staffCategoryPrices: function staffCategoryPrices(prices) {
    var _this = this;
    return prices.map(function (price) {
      return _this.staffCategoryPrice(price);
    });
  },
  staffCategoryPrice: function staffCategoryPrice(_staffCategoryPrice) {
    return {
      staffCategoryId: _staffCategoryPrice.staff_category_id,
      price: _staffCategoryPrice.price
    };
  },
  /** Mapping Services */services: function services(payload) {
    var services = payload.services,
      linked = payload.linked;
    return {
      services: services.map(function (item) {
        return Mapper.service(item, linked);
      })
    };
  },
  service: function service(_service, linked) {
    // In case we have a non-returned service in the details / course links
    // let's exit here and just return an empty object.
    if (_service == null) {
      return {};
    }
    var category = {
      id: _service.service_category_id
    };
    if (linked) {
      var _linked$service_categ;
      var serviceCategory = (_linked$service_categ = linked.service_categories) === null || _linked$service_categ === void 0 ? void 0 : _linked$service_categ.find(function (item) {
        return item.id === _service.service_category_id;
      });
      category.name = serviceCategory ? capitalize(serviceCategory.name) : '';
    }
    var staffCategoryPrices;
    if (_service.staff_category_prices) {
      staffCategoryPrices = _service.staff_category_prices.map(function (item) {
        return Mapper.staffCategoryPrice(item);
      });
    }
    return {
      id: _service.id,
      name: capitalize(_service.name),
      price: _service.price,
      duration: _service.duration,
      patchTestRequired: _service.patch_test_required,
      description: _service.description,
      gapTime: _service.gap_time,
      discount: Mapper.discount(_service.discount),
      isArchived: _service.archived,
      internetEnabled: _service.internet_enabled,
      pricedFromDisclaimer: _service.priced_from_disclaimer,
      staffCategoryPrices: staffCategoryPrices,
      category: category
    };
  },
  serviceCategories: function serviceCategories(serviceCategory) {
    return {
      serviceCategories: serviceCategory.service_categories.map(function (item) {
        return Mapper.serviceCategory(item);
      })
    };
  },
  serviceCategory: function serviceCategory(_serviceCategory) {
    return {
      id: _serviceCategory.id,
      name: capitalize(_serviceCategory.name),
      description: _serviceCategory.description,
      internetCategoryIds: _serviceCategory.internet_category_ids,
      internetCategoryGroups: _serviceCategory.internet_category_groups
    };
  },
  /** Mapping Service Groups */serviceGroups: function serviceGroups(payload) {
    var service_groups = payload.service_groups,
      linked = payload.linked;
    return {
      serviceGroups: service_groups.map(function (item) {
        return Mapper.serviceGroup(item, linked);
      })
    };
  },
  serviceGroup: function serviceGroup(_serviceGroup, linked) {
    var items = _serviceGroup.items.map(function (itemJson) {
      var options = itemJson.options.map(function (optionJson) {
        var _optionJson$staff_cat;
        var service = Mapper.service(linked.services.find(function (item) {
          return item.id === optionJson.service_id;
        }));
        var staffCategoryPrices = Mapper.staffCategoryPrices((_optionJson$staff_cat = optionJson.staff_category_prices) !== null && _optionJson$staff_cat !== void 0 ? _optionJson$staff_cat : []);
        service.staffCategoryPrices = staffCategoryPrices;
        return {
          id: optionJson.id,
          price: optionJson.price,
          service: service,
          staffCategoryPrices: staffCategoryPrices,
          primary: itemJson.primary
        };
      });
      return {
        primary: itemJson.primary,
        options: options
      };
    });
    return {
      id: _serviceGroup.id,
      name: capitalize(_serviceGroup.name),
      description: _serviceGroup.description,
      numberOfClients: _serviceGroup.num_persons,
      isArchived: _serviceGroup.archived,
      internetEnabled: _serviceGroup.internet_enabled,
      specialOffer: _serviceGroup.special_offer,
      validityStart: _serviceGroup.validity_start,
      validityEnd: _serviceGroup.validity_end,
      items: items,
      category: {
        id: _serviceGroup.service_category_id
      },
      excludedStaffIds: _serviceGroup.excluded_staff_ids,
      includedStaffIds: _serviceGroup.included_staff_ids
    };
  },
  /** Mapping availabilities */availabilityRequest: function availabilityRequest(_availabilityRequest) {
    function serviceSelection(service) {
      return {
        service_id: service.serviceId,
        staff_id: service.staffId,
        service_group_id: service.serviceGroupId,
        service_group_item_option_id: service.serviceGroupItemOptionId,
        client_course_item_id: service.clientCourseItemId,
        service_reward_id: service.serviceRewardId
      };
    }
    return {
      availability_requests: {
        start_time: _availabilityRequest.startTime,
        end_time: _availabilityRequest.endTime,
        client_service_selections: _availabilityRequest.selection.map(function (selection) {
          return {
            client_id: selection.clientId,
            service_selections: selection.services.map(function (service) {
              return serviceSelection(service);
            })
          };
        })
      }
    };
  },
  availabilities: function availabilities(payload) {
    var availability_slots = payload.availability_slots,
      meta = payload.meta;
    return {
      availabilities: availability_slots.map(function (item) {
        return Mapper.availability(item);
      }),
      lastAllowedDate: meta === null || meta === void 0 ? void 0 : meta.last_allowed_date
    };
  },
  availability: function availability(_availability) {
    var clientSchedules = _availability.client_schedules.map(function (clientSchedule) {
      var serviceSchedules = clientSchedule.service_schedules.map(function (serviceSchedule) {
        return {
          startTime: serviceSchedule.start_time,
          endTime: serviceSchedule.end_time,
          serviceId: serviceSchedule.service_id,
          staffId: serviceSchedule.staff_id,
          alternativeStaff: serviceSchedule.alternative_staff_member,
          serviceGroupId: serviceSchedule.service_group_id,
          serviceGroupItemOptionId: serviceSchedule.service_group_item_option_id,
          clientCourseItemId: serviceSchedule.client_course_item_id,
          serviceRewardId: serviceSchedule.service_reward_id,
          requestedStaffId: serviceSchedule.requested_staff_id
        };
      });
      return {
        serviceSchedules: serviceSchedules
      };
    });
    return {
      startTime: _availability.start_time,
      clientSchedules: clientSchedules
    };
  },
  clientSchedules: function clientSchedules(_clientSchedules) {
    return _clientSchedules.map(function (item) {
      return Mapper.clientSchedule(item);
    });
  },
  clientSchedule: function clientSchedule(_clientSchedule) {
    var serviceSchedules = _clientSchedule.serviceSchedules.map(function (serviceSchedule) {
      return {
        startTime: serviceSchedule.start_time,
        endTime: serviceSchedule.end_time,
        serviceId: serviceSchedule.service_id,
        staffId: serviceSchedule.staff_id,
        alternativeStaff: serviceSchedule.alternative_staff_member
      };
    });
    return {
      serviceSchedules: serviceSchedules
    };
  },
  serviceSchedules: function serviceSchedules(_serviceSchedules) {
    return _serviceSchedules.map(function (item) {
      return Mapper.serviceSchedule(item);
    });
  },
  serviceSchedule: function serviceSchedule(_serviceSchedule) {
    return {
      startTime: _serviceSchedule.start_time,
      endTime: _serviceSchedule.end_time,
      serviceId: _serviceSchedule.service_id,
      staffId: _serviceSchedule.staff_id,
      serviceGroupId: _serviceSchedule.service_group_id,
      serviceGroupItemOptionId: _serviceSchedule.service_group_item_option_id,
      clientCourseItemId: _serviceSchedule.client_course_item_id,
      serviceRewardId: _serviceSchedule.service_reward_id,
      alternativeStaff: _serviceSchedule.alternative_staff_member,
      requestedStaffId: _serviceSchedule.staff_id,
      staffRequested: _serviceSchedule.staff_requested
    };
  },
  /* Reservations  */reservationRequest: function reservationRequest(reservation) {
    function mapServiceSchedule(item) {
      return {
        start_time: item.startTime,
        end_time: item.endTime,
        service_id: item.serviceId,
        staff_id: item.staffId,
        service_group_id: item.serviceGroupId || null,
        service_group_item_option_id: item.serviceGroupItemOptionId || null,
        client_course_item_id: item.clientCourseItemId || null,
        service_reward_id: item.serviceRewardId || null,
        staff_request: item.staffRequested === true || item.requestedStaffId != null
      };
    }

    // this is a group booking and requires a different request object model
    if (reservation.clientSchedules.length > 1) {
      return {
        appointment_booking_option_groups: {
          appointment_booking_options: reservation.clientSchedules.map(function (clientSchedule) {
            return {
              service_schedules: clientSchedule.serviceSchedules.map(function (item) {
                return mapServiceSchedule(item);
              })
            };
          })
        }
      };
    }
    return {
      appointment_booking_options: {
        service_schedules: reservation.clientSchedules[0].serviceSchedules.map(function (item) {
          return mapServiceSchedule(item);
        })
      }
    };
  },
  discount: function discount(payload) {
    if (!payload || !(payload.type === DiscountType.AMOUNT || payload.type === DiscountType.PERCENT) || !payload.value) {
      return undefined;
    }
    return {
      type: payload.type,
      value: payload.value
    };
  },
  // The payment type at appointment level for stripe comes either as STRIPE_CARD_PAYMENT or STRIPE_CARD_CAPTURE
  // In reality it could be a STRIPE_MD but we only know that by checking susequtially the activation details
  // Bear in mind that the activation details may report STRIPE_MD even if is NO_CARD_REQUIRED
  isStripeMicroDeposit: function isStripeMicroDeposit(appointment_reservations) {
    var _appointment_reservat, _appointment_reservat2;
    return appointment_reservations.payment_type.startsWith('STRIPE') && ((_appointment_reservat = appointment_reservations.activation_details) === null || _appointment_reservat === void 0 ? void 0 : _appointment_reservat.payment_provider) === 'STRIPE_MD' && ((_appointment_reservat2 = appointment_reservations.activation_details) === null || _appointment_reservat2 === void 0 || (_appointment_reservat2 = _appointment_reservat2.stripe) === null || _appointment_reservat2 === void 0 ? void 0 : _appointment_reservat2.payment_intent_secret) != null;
  },
  reservation: function reservation(payload) {
    var appointment_reservations = payload.appointment_reservations,
      linked = payload.linked;
    var appointments = appointment_reservations.links.appointments.map(function (id) {
      var appointment = linked.appointments.find(function (item) {
        return item.id === id;
      });
      return Mapper.appointment(appointment, linked.services, linked.staffs);
    });

    // The payment type at reservation level is needed because of the legacy methods of payment Bancard and Stripe Micro Deposit
    // Once those are removed (in 2025) we can remove the paymentType from the reservation object
    // and keep the reservationPayments object as unique source of truth for the payment type
    var paymentType;
    switch (appointment_reservations.payment_type) {
      case 'BANCARD_CARD_CAPTURE':
        paymentType = PaymentTypeEnum.Bancard;
        break;
      case 'STRIPE_CARD_PAYMENT':
        paymentType = PaymentTypeEnum.StripePayment;
        break;
      case 'STRIPE_CARD_CAPTURE':
        paymentType = PaymentTypeEnum.StripeCapture;
        break;
      case 'NO_CARD_REQUIRED':
        paymentType = PaymentTypeEnum.NoCardRequired;
        break;
      default:
        paymentType = PaymentTypeEnum.Unknown;
    }
    if (Mapper.isStripeMicroDeposit(appointment_reservations)) {
      paymentType = PaymentTypeEnum.StripePaymentMicroDeposit;
    }
    return {
      id: appointment_reservations.id,
      total: appointment_reservations.total_amount,
      deposit: appointment_reservations.deposit_amount,
      bancardToken: appointment_reservations.bancard_passport_connect_public_token,
      discount: Mapper.discount(appointment_reservations.discount),
      activationStatus: appointment_reservations.activation_status,
      appointments: appointments,
      paymentType: paymentType,
      payments: Mapper.reservationPayments(appointment_reservations).payments
    };
  },
  reservationPayments: function reservationPayments(reservation) {
    if (!isEmpty(reservation === null || reservation === void 0 ? void 0 : reservation.payments)) {
      return {
        payments: reservation.payments.map(function (item) {
          return Mapper.reservationPayment(item);
        })
      };
    } else if (Mapper.isStripeMicroDeposit(reservation)) {
      return {
        payments: [{
          type: PaymentTypeEnum.StripePaymentMicroDeposit,
          amount: reservation.deposit_amount,
          stripe: {
            publishableKey: reservation.activation_details.stripe.publishable_key,
            intentSecret: reservation.activation_details.stripe.payment_intent_secret
          }
        }]
      };
    }
    return {
      payments: []
    };
  },
  reservationPayment: function reservationPayment(payment) {
    var reservationPayment = {
      amount: payment.amount,
      serial: payment.details.serial
    };
    // In here we support only the payments that are not deprecated: VOUCHER, STRIPE_CARD_PAYMENT, STRIPE_CARD_CAPTURE
    // Bancard and Stripe Micro Depost will phase out in 2025
    // The no card required is modelled as empty array of payments
    switch (payment.payment_type) {
      case 'VOUCHER':
        reservationPayment.type = PaymentTypeEnum.Voucher;
        break;
      case 'STRIPE_CARD_PAYMENT':
        reservationPayment.type = PaymentTypeEnum.StripePayment;
        break;
      case 'STRIPE_CARD_CAPTURE':
        reservationPayment.type = PaymentTypeEnum.StripeCapture;
        break;
      default:
        reservationPayment.type = PaymentTypeEnum.Unknown;
    }
    if (reservationPayment.type === PaymentTypeEnum.StripePayment || reservationPayment.type === PaymentTypeEnum.StripeCapture) {
      reservationPayment.stripe = {
        publishableKey: payment.details.stripe_publishable_key,
        intentSecret: payment.details.stripe_payment_intent_secret
      };
    }
    return reservationPayment;
  },
  appointments: function appointments(_appointments) {
    var services = _appointments.linked.services;
    var staffs = _appointments.linked.staffs;
    return _appointments.appointments.map(function (appointment) {
      return Mapper.appointment(appointment, services, staffs);
    });
  },
  appointment: function appointment(_appointment, services, staffs) {
    var serviceJSON = services.find(function (item) {
      return item.id === _appointment.service_id;
    });
    var staffJSON = staffs.find(function (item) {
      return item.id === _appointment.staff_id;
    });
    return {
      id: _appointment.id,
      staff: Mapper.staff(staffJSON),
      service: Mapper.service(serviceJSON),
      serviceGroupId: _appointment.service_group_id,
      startTime: _appointment.start_time,
      endTime: _appointment.end_time,
      price: _appointment.price,
      staffRequest: _appointment.staff_request,
      state: _appointment.state,
      activationState: _appointment.activation_state,
      groupBookingId: _appointment.group_booking_id
    };
  },
  serviceHistories: function serviceHistories(payload) {
    var service_history_entries = payload.service_history_entries,
      linked = payload.linked;
    return {
      serviceHistories: service_history_entries.map(function (item) {
        return Mapper.serviceHistory(item, linked);
      })
    };
  },
  serviceHistory: function serviceHistory(serviceHistoryEntries, linked) {
    var details = serviceHistoryEntries.details.map(function (detail) {
      var serviceJSON = linked.services.find(function (item) {
        return item.id === detail.links.service;
      });
      if (!serviceJSON) {
        console.log('This is the service id we cannot find', detail.links.service);
      }
      var staffJSON = linked.staffs.find(function (item) {
        return item.id === detail.links.staff;
      });
      if (!staffJSON) {
        console.log('This is the staff id we cannot find', detail.links.staff);
      }
      return {
        time: detail.time,
        staffId: detail.staff_id,
        depositAmount: detail.deposit_amount,
        depositDate: detail.deposit_date,
        serviceId: detail.service_id,
        serviceGroupId: detail.service_group_id,
        clientCourseItemId: detail.client_course_item_id,
        serviceRewardId: detail.service_reward_id,
        groupBookingId: detail.group_booking_id,
        groupBookingNumberOfClients: detail.group_booking_number_of_people,
        cancelled: detail.cancelled,
        description: detail.description,
        fee: detail.fee,
        discount: detail.discount,
        originalPrice: detail.original_price,
        duration: detail.duration,
        staff: Mapper.staff(staffJSON),
        service: Mapper.service(serviceJSON)
      };
    });
    return {
      id: serviceHistoryEntries.id,
      date: serviceHistoryEntries.date,
      details: details
    };
  },
  recentServiceHistory: function recentServiceHistory(payload) {
    var service_history_entries = payload.service_history_entries,
      linked = payload.linked;
    var details = service_history_entries.details.map(function (detail) {
      var serviceJSON = linked.services.find(function (item) {
        return item.id === detail.links.service;
      });
      var staffJSON = linked.staffs.find(function (item) {
        return item.id === detail.links.staff;
      });
      return {
        time: detail.time,
        staffId: detail.staff_id,
        depositAmount: detail.deposit_amount,
        depositDate: detail.deposit_date,
        serviceId: detail.service_id,
        clientCourseItemId: detail.client_course_item_id,
        serviceRewardId: detail.service_reward_id,
        groupBookingId: detail.group_booking_id,
        cancelled: detail.cancelled,
        description: detail.description,
        fee: detail.fee,
        discount: detail.discount,
        originalPrice: detail.original_price,
        duration: detail.duration,
        staffs: Mapper.staff(staffJSON),
        services: Mapper.service(serviceJSON)
      };
    });
    return {
      id: service_history_entries.id,
      date: service_history_entries.date,
      details: details
    };
  },
  authenticationRequest: function authenticationRequest(request) {
    var auth = {
      authentication_tokens: {
        password: request.password
      }
    };
    var username = request.username;
    if (username.includes('@')) {
      auth.authentication_tokens.email = username;
    } else {
      auth.authentication_tokens.phone_number = username;
    }
    return auth;
  },
  authentication: function authentication(payload) {
    var authentication_tokens = payload.authentication_tokens;
    return {
      token: authentication_tokens.id
    };
  },
  user: function user(payload) {
    var users = payload.users,
      linked = payload.linked;
    var loyaltyCard;
    var loyaltyAccount;
    if (users.loyalty_card) {
      loyaltyCard = {
        serial: users.loyalty_card.serial,
        matrixDisabled: users.loyalty_card.matrix_disabled
      };
    }
    if (linked && linked.loyalty_account) {
      loyaltyAccount = {
        id: linked.loyalty_account.id,
        points: linked.loyalty_account.points
      };
    }
    return {
      // need to check if linked.loyaltyaccount exists
      id: users.id,
      onlineBookingCredit: users.online_booking_credit,
      firstName: users.first_name,
      lastName: users.last_name,
      email: users.email,
      phoneNumber: users.phone_number,
      phonePrefix: users.phone_number_prefix,
      loyaltyCard: loyaltyCard,
      loyaltyAccount: loyaltyAccount
    };
  },
  registeredUser: function registeredUser(payload) {
    var users = payload.users;
    return {
      id: users.id,
      firstName: users.first_name,
      lastName: users.last_name,
      email: users.email,
      phoneNumber: users.phone_number,
      authentication: {
        token: users.authentication_token
      }
    };
  },
  userRegistrationRequest: function userRegistrationRequest(userRequest) {
    var _userRequest$fullName = userRequest.fullName.trim().split(' '),
      _userRequest$fullName2 = _toArray(_userRequest$fullName),
      head = _userRequest$fullName2[0],
      tail = _userRequest$fullName2.slice(1);
    var firstName = head;
    var lastName = tail.join(' ');
    return {
      users: {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: userRequest.email.trim(),
        phone_number: userRequest.phone.trim(),
        phone_number_prefix: userRequest.phonePrefix.trim(),
        password: userRequest.password,
        password_confirmation: userRequest.password
      }
    };
  },
  userProfileSettings: function userProfileSettings(payload) {
    var users = payload.users;
    return {
      firstName: users.first_name,
      lastName: users.last_name,
      email: users.email,
      phoneNumber: users.phone_number
    };
  },
  userSettingsRequest: function userSettingsRequest(userRequest) {
    var _userRequest$fullName3 = userRequest.fullName.trim().split(' '),
      _userRequest$fullName4 = _toArray(_userRequest$fullName3),
      head = _userRequest$fullName4[0],
      tail = _userRequest$fullName4.slice(1);
    var firstName = head;
    var lastName = tail.join(' ');
    return {
      users: {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: userRequest.email.trim(),
        phone_number: userRequest.phoneNumber.trim(),
        phone_number_prefix: userRequest.phoneNumberPrefix.trim()
      }
    };
  },
  updatePasswordRequest: function updatePasswordRequest(_updatePasswordRequest) {
    return {
      users: {
        password: _updatePasswordRequest.password,
        password_confirmation: _updatePasswordRequest.confirmPassword
      }
    };
  },
  resetPasswordRequest: function resetPasswordRequest(_resetPasswordRequest) {
    return {
      passwords: {
        email: _resetPasswordRequest.email
      }
    };
  },
  changePasswordRequest: function changePasswordRequest(_changePasswordRequest) {
    return {
      passwords: {
        password: _changePasswordRequest.passwords.password,
        password_confirmation: _changePasswordRequest.passwords.passwordConfirmation
      }
    };
  },
  storedCards: function storedCards(payload) {
    var cards = payload.stored_cards.map(function (card) {
      return {
        id: "".concat(card.id),
        type: card.type,
        digits: padWithLeadingZeroes(card.digits, 4),
        expiry: card.expiry,
        preferred: card.preferred,
        token: card.token
      };
    });
    return {
      cards: cards
    };
  },
  bancardActivationRequest: function bancardActivationRequest(request) {
    if (request.token) {
      return {
        appointment_activations: {
          appointment_reservation_id: request.reservationId,
          type: 'BANCARD_NSP_NEW_CARD',
          token: request.token
        }
      };
    }
    if (request.cardId) {
      return {
        appointment_activations: {
          appointment_reservation_id: request.reservationId,
          type: 'BANCARD_NSP_STORED_CARD',
          bancard_stored_card_id: request.cardId
        }
      };
    }
  },
  reservationActivationRequest: function reservationActivationRequest(request) {
    switch (request.paymentType) {
      case PaymentTypeEnum.Bancard:
        return Mapper.bancardActivationRequest(request);
      case PaymentTypeEnum.NoCardRequired:
        return {
          appointment_activations: {
            appointment_reservation_id: request.reservationId,
            type: 'FREE'
          }
        };
      default:
        console.log('Internal inconsistency: only Bancard or NO CC provider use activation request anymore.');
        return null;
    }
  },
  reservationActivation: function reservationActivation(payload) {
    var appointment_activations = payload.appointment_activations;
    return {
      id: appointment_activations.id,
      status: appointment_activations.status
    };
  },
  reservationStatus: function reservationStatus(payload) {
    var appointment_reservations = payload.appointment_reservations;
    return {
      id: appointment_reservations.id,
      status: appointment_reservations.status
    };
  },
  stripeWebToCreditCard: function stripeWebToCreditCard(details) {
    var card = details.card;
    return {
      id: generateRandomId(),
      type: card.brand,
      digits: card.last4,
      expiry: "".concat(card.exp_year, "-").concat(padWithLeadingZeroes(card.exp_month, 2), "-01"),
      token: details.id,
      preferred: false
    };
  },
  stripeCardToCreditCard: function stripeCardToCreditCard(details, paymentMethodId) {
    return {
      id: generateRandomId(),
      type: details.brand,
      digits: details.last4,
      expiry: "".concat(details.expiryYear, "-").concat(padWithLeadingZeroes(details.expiryMonth, 2), "-01"),
      token: paymentMethodId,
      preferred: false
    };
  },
  bancardCardToCreditCard: function bancardCardToCreditCard(card) {
    return {
      id: generateRandomId(),
      type: card.brand,
      digits: card.lastFour,
      expiry: card.expiration,
      token: card.token,
      preferred: false
    };
  },
  appointmentCancellationRequest: function appointmentCancellationRequest(request) {
    return {
      appointment_cancellations: {
        date: request.date
      }
    };
  },
  business: function business(payload) {
    var businesses = payload.businesses,
      linked = payload.linked;
    var branches = linked.branches.map(function (b) {
      return Mapper.branch(b);
    });
    return {
      id: businesses.id,
      name: businesses.name,
      silo: businesses.silo,
      locale: {
        country: businesses.locale.country,
        lang: businesses.locale.lang
      },
      branches: branches
    };
  },
  branches: function branches(payload) {
    var branches = payload.branches;
    return branches.map(function (b) {
      return Mapper.branch(b);
    });
  },
  branch: function branch(payload) {
    var _payload$ecommerce_se, _payload$ecommerce_se2;
    var defaultWorkWeek = payload.default_work_week != null ? {
      weekStartDay: payload.default_work_week.week_start_day,
      days: payload.default_work_week.days.map(function (day) {
        return {
          name: day.name,
          times: day.working_times.map(function (time) {
            return {
              start: time.start,
              end: time.end
            };
          })
        };
      })
    } : undefined;
    var coordinates = payload.coordinates ? {
      latitude: payload.coordinates.latitude,
      longitude: payload.coordinates.longitude
    } : undefined;
    var ecommerceSettings = {
      onlineStoreEnabled: payload === null || payload === void 0 || (_payload$ecommerce_se = payload.ecommerce_settings) === null || _payload$ecommerce_se === void 0 ? void 0 : _payload$ecommerce_se.online_store_enabled,
      onlineStoreUrl: payload === null || payload === void 0 || (_payload$ecommerce_se2 = payload.ecommerce_settings) === null || _payload$ecommerce_se2 === void 0 ? void 0 : _payload$ecommerce_se2.online_store_url
    };
    return {
      id: payload.id,
      domainName: payload.domain_name,
      name: payload.name,
      timezone: payload.time_zone,
      address: {
        streetAddress1: payload.address.street_address_1,
        streetAddress2: payload.address.street_address_2,
        city: payload.address.city,
        state: payload.address.state,
        postalCode: payload.address.postal_code,
        country: payload.address.country
      },
      coordinates: coordinates,
      phone: payload.phone,
      email: payload.email,
      facebookUrl: payload.facebook_url,
      onlineBookingFirstNameOnly: payload.online_booking_first_name_only,
      cancellationPolicyHours: payload.cancellation_policy_hours,
      patchTestPolicyHours: payload.patch_test_policy_hours,
      onlineDiscountFrequency: payload.online_discount_frequency,
      onlineDiscount: Mapper.discount(payload.online_discount),
      appointmentNotesVisible: payload.appointment_notes_visible,
      appointmentNotesLabel: payload.appointment_notes_label,
      defaultWorkWeek: defaultWorkWeek,
      privacyPolicyUrl: payload.privacy_policy_url,
      creditCardProvider: payload.credit_card_provider,
      noShowProtection: payload.no_show_protection_enabled !== undefined ? payload.no_show_protection_enabled : false,
      onlineBookingEnabled: payload.online_booking_enabled !== undefined ? payload.online_booking_enabled : true,
      termsUrl: payload.terms_url,
      treatcardEnableStatus: payload.treatcard_enable_status,
      galleryEnabled: payload.gallery_enabled,
      onlineGiftCardsEnabled: payload.online_gift_cards_enabled,
      onlineBookingAnnouncementEnabled: payload.online_booking_announcement_enabled,
      onlineBookingAnnouncementContent: payload.online_booking_announcement_content,
      waitListEnabled: payload.waitlist_enabled !== undefined ? payload.waitlist_enabled : false,
      waitListUrl: payload.waitlist_url,
      brandedAppEnabled: payload.branded_app_enabled !== undefined ? payload.branded_app_enabled : true,
      covidDisclaimerEnabled: payload.covid_disclaimer_enabled !== undefined ? payload.covid_disclaimer_enabled : false,
      covidDisclaimer: payload.covid_disclaimer,
      ecommerceSettings: ecommerceSettings,
      vipBookingEnabled: payload.vip_booking_enabled,
      onlineStaffSelectionRule: payload.online_staff_selection_rule,
      loyaltyReferralsFeatureEnabled: payload.loyalty_referrals_feature_enabled,
      leadManagementEnabled: payload.lead_management_enabled,
      defaultCourseExpirationMonths: payload.default_course_expiration_months,
      isCoursePurchasesEnabled: payload.purchase_courses_enabled
    };
  },
  brand: function brand(payload) {
    var _payload$branded_app_ = payload.branded_app_themes,
      id = _payload$branded_app_.id,
      colors = _payload$branded_app_.colors,
      images = _payload$branded_app_.images,
      theme_name = _payload$branded_app_.theme_name;
    return {
      id: id,
      colors: {
        main: colors.main
      },
      images: {
        logo: images.logo,
        hero: images.hero
      },
      themeName: theme_name
    };
  },
  appointmentReschedule: function appointmentReschedule(payload) {
    var appointment_reschedules = payload.appointment_reschedules,
      linked = payload.linked;
    var appointments = appointment_reschedules.links.appointments.map(function (appointmentId) {
      var appointment = linked.appointments.find(function (item) {
        return item.id === appointmentId;
      });
      return Mapper.appointment(appointment, linked.services, linked.staffs);
    });
    return {
      appointments: appointments
    };
  },
  appointmentRescheduleRequest: function appointmentRescheduleRequest(request) {
    return {
      appointment_reschedules: {
        original_date: request.originalDate,
        service_schedules: request.availability.clientSchedules[0].serviceSchedules.map(function (item) {
          return {
            start_time: item.startTime,
            end_time: item.endTime,
            service_id: item.serviceId,
            staff_id: item.staffId,
            service_group_id: item.serviceGroupId || null,
            service_group_item_option_id: item.serviceGroupItemOptionId || null,
            client_course_item_id: item.clientCourseItemId || null,
            service_reward_id: item.serviceRewardId || null,
            staff_request: false
          };
        })
      }
    };
  },
  groupAppointmentRescheduleRequest: function groupAppointmentRescheduleRequest(request) {
    return {
      appointment_group_reschedules: {
        group_booking_id: request.groupAppointmentId,
        appointment_booking_options: request.availability.clientSchedules.map(function (clientSchedule) {
          return {
            service_schedules: clientSchedule.serviceSchedules.map(function (item) {
              return {
                start_time: item.startTime,
                end_time: item.endTime,
                service_id: item.serviceId,
                staff_id: item.staffId,
                service_group_id: item.serviceGroupId || null,
                service_group_item_option_id: item.serviceGroupItemOptionId || null,
                client_course_item_id: item.clientCourseItemId || null,
                service_reward_id: item.serviceRewardId || null,
                staff_request: false
              };
            })
          };
        })
      }
    };
  },
  patchTests: function patchTests(_patchTests) {
    return {
      patchTests: _patchTests.patch_tests.map(function (item) {
        return Mapper.patchTest(item);
      })
    };
  },
  patchTest: function patchTest(_patchTest) {
    return {
      archived: _patchTest.archived,
      name: _patchTest.name,
      date: _patchTest.date,
      positive: _patchTest.positive,
      staffId: _patchTest.staff_id
    };
  },
  clientCourses: function clientCourses(payload) {
    var client_courses = payload.client_courses,
      linked = payload.linked;
    return {
      clientCourses: client_courses.map(function (clientCourse) {
        return Mapper.clientCourse(clientCourse, linked);
      })
    };
  },
  clientCourse: function clientCourse(_clientCourse, linked) {
    var items = _clientCourse.items.map(function (item) {
      var serviceJSON = linked.services.find(function (detail) {
        return detail.id === item.service_id;
      });
      return {
        id: item.id,
        initialUnits: item.initial_units,
        unitsRemaining: item.units_remaining,
        unitType: item.unit_type,
        courseItemId: item.course_item_id,
        serviceId: item.service_id,
        service: Mapper.service(serviceJSON)
      };
    });
    return {
      id: _clientCourse.id,
      courseName: _clientCourse.course_name,
      courseId: _clientCourse.course_id,
      items: items
    };
  },
  referralSettings: function referralSettings(payload) {
    return {
      enabled: payload.enabled,
      referrerRewardExpiresAfterDays: payload.referrer_reward_expires_after_days,
      referrerRewardAmount: payload.referrer_reward_amount,
      newClientRewardExpiresAfterDays: payload.new_client_reward_expires_after_days,
      newClientRewardAmount: payload.new_client_reward_amount
    };
  },
  referralLink: function referralLink(payload) {
    return {
      code: payload.code,
      shortLink: payload.short_link
    };
  },
  referralRegistrationRequest: function referralRegistrationRequest(request) {
    var user = {
      first_name: request.firstName,
      last_name: request.lastName,
      email: request.email,
      phone_number: request.phone,
      code: request.code
    };
    return {
      user: user
    };
  },
  consultationFormRequest: function consultationFormRequest(request) {
    return _objectSpread({}, request);
  },
  appointmentNotesRequest: function appointmentNotesRequest(_appointmentNotesRequest) {
    return {
      appointment_notes: {
        appointment_activation_id: _appointmentNotesRequest.appointmentActivationId,
        text: _appointmentNotesRequest.text
      }
    };
  },
  serviceRewards: function serviceRewards(payload) {
    var service_rewards = payload.service_rewards,
      linked = payload.linked;
    return {
      serviceRewards: service_rewards.map(function (item) {
        return Mapper.serviceReward(item, linked);
      })
    };
  },
  serviceReward: function serviceReward(payload, linked) {
    var services = linked.services.map(function (service) {
      return Mapper.service(service);
    });
    return {
      id: payload.id,
      name: payload.name,
      points: payload.points,
      archived: payload.archived,
      service: services.find(function (service) {
        return service.id === payload.links.service;
      })
    };
  },
  gallery: function gallery(payload) {
    return {
      images: payload.images.map(function (item) {
        return Mapper.galleryImage(item);
      })
    };
  },
  galleryImage: function galleryImage(item) {
    return {
      id: item.id,
      url: item.url
    };
  },
  featureFlags: function featureFlags(payload) {
    return payload.feature_flags.map(function (flag) {
      return {
        name: flag.flag_name,
        enabled: flag.enabled
      };
    });
  },
  branchSettings: function branchSettings(payload) {
    var data = payload.data;
    return {
      id: data.id,
      type: data.type,
      businessId: data.attributes.mem_business_id,
      branchId: data.attributes.mem_branch_id,
      brandColor: data.attributes.brand_color,
      widgetButtonColor: data.attributes.widget_button_color,
      locale: data.attributes.locale,
      enableBranchSelector: data.attributes.enable_branch_selector,
      onlineBookingEnabled: data.attributes.online_booking_enabled,
      vipBookingEnabled: data.attributes.vip_booking_enabled,
      brandedAppEnabled: data.attributes.branded_app_enabled === true
    };
  },
  loyaltySettings: function loyaltySettings(payload) {
    return {
      showTreatCardOnline: payload.show_treat_card_online,
      showTreatCardPoints: payload.show_treat_card_points,
      showTreatCardRewards: payload.show_treat_card_rewards,
      allowTreatCardRewardsRedeem: payload.allow_treat_card_rewards_redeem
    };
  },
  bootstrap: function bootstrap(payload) {
    var data = payload.data;
    function clearUnconventialNullFields(field) {
      if (field && (field === 'None' || field === 'null')) {
        return null;
      }
      return field;
    }
    return {
      id: data.id,
      type: data.type,
      facebookPixelId: clearUnconventialNullFields(data.attributes.fb_pixel_id),
      facebookLocale: data.attributes.facebook_locale,
      facebookAppId: data.attributes.facebook_app_id,
      gaTrackId: clearUnconventialNullFields(data.attributes.google_analytics_tracking_id),
      gaDomain: clearUnconventialNullFields(data.attributes.google_analytics_cookie_domain),
      gaPrimaryTrackId: clearUnconventialNullFields(data.attributes.google_analytics_primary_domain_tracking_id),
      gaPrimaryDomain: clearUnconventialNullFields(data.attributes.google_analytics_primary_domain_name),
      logEntriesToken: data.attributes.logentries_token,
      mode: data.attributes.mode,
      gtmContainerId: clearUnconventialNullFields(data.attributes.gtm_container_id),
      onlineBookingEnabled: data.attributes.online_booking_enabled,
      waitListEnabled: data.attributes.waitlist_enabled,
      vipBookingEnabled: data.attributes.vip_booking_enabled
    };
  },
  ad: function ad(payload) {
    return {
      id: payload.id,
      name: payload.name,
      type: payload.type,
      status: payload.status,
      imageUrl: payload.image_url,
      primaryText: payload.primary_text,
      headline: payload.headline
    };
  },
  abandonedCart: function abandonedCart(payload) {
    var appointments = payload.appointments,
      linked = payload.linked;
    var result = appointments.map(function (appointmentItem) {
      var serviceJSON = linked.services.find(function (service) {
        return service.id === appointmentItem.service_id;
      });
      var staffJSON = linked.staffs.find(function (staff) {
        return staff.id === appointmentItem.staff_id;
      });
      return {
        id: appointmentItem.id,
        startTime: appointmentItem.start_time,
        endTime: appointmentItem.end_time,
        price: appointmentItem.price,
        staffRequest: appointmentItem.staff_request,
        state: appointmentItem.state,
        activationState: appointmentItem.activation_state,
        service: Mapper.service(serviceJSON),
        staff: Mapper.staff(staffJSON),
        serviceGroupId: appointmentItem.service_group_id
      };
    });
    return {
      appointments: result
    };
  },
  signInLinkRequest: function signInLinkRequest(request) {
    return {
      sign_in_link: {
        phone_or_email: request.username
      }
    };
  },
  userPaymentConfig: function userPaymentConfig(payload) {
    return {
      customerSessionClientSecret: payload.customer_session_client_secret,
      customerId: payload.customer_session_id
    };
  },
  branchCourseItem: function branchCourseItem(payload, linked) {
    var _linked$services$find, _linked$services, _Object$values$find;
    var id = payload.id,
      unitType = payload.unit_type,
      serviceId = payload.service_id,
      totalUnits = payload.total_units;
    var service = (_linked$services$find = linked === null || linked === void 0 || (_linked$services = linked.services) === null || _linked$services === void 0 ? void 0 : _linked$services.find(function (item) {
      return item.id === serviceId;
    })) !== null && _linked$services$find !== void 0 ? _linked$services$find : null;
    return {
      id: id,
      unitType: (_Object$values$find = Object.values(CourseUnitType).find(function (value) {
        return (unitType === null || unitType === void 0 ? void 0 : unitType.toUpperCase()) === value;
      })) !== null && _Object$values$find !== void 0 ? _Object$values$find : undefined,
      totalUnits: totalUnits,
      serviceId: serviceId,
      service: service ? Mapper.service(service, linked) : null
    };
  },
  branchCourse: function branchCourse(payload, linked) {
    var _branchCourseItems$;
    var id = payload.id,
      name = payload.name,
      description = payload.internet_description,
      multiBranch = payload.multi_branch,
      items = payload.items,
      totalPrice = payload.total_price,
      minDaysBetweenSessions = payload.min_days_between_sessions,
      maxDaysBetweenSessions = payload.max_days_between_sessions;
    var branchCourseItems = items.map(function (item) {
      return Mapper.branchCourseItem(item, linked);
    });
    return {
      id: id,
      name: name,
      description: description,
      multiBranch: multiBranch,
      items: branchCourseItems,
      totalPrice: totalPrice,
      minDaysBetweenSessions: minDaysBetweenSessions,
      maxDaysBetweenSessions: maxDaysBetweenSessions,
      category: branchCourseItems === null || branchCourseItems === void 0 || (_branchCourseItems$ = branchCourseItems[0]) === null || _branchCourseItems$ === void 0 || (_branchCourseItems$ = _branchCourseItems$.service) === null || _branchCourseItems$ === void 0 ? void 0 : _branchCourseItems$.category
    };
  },
  branchCourses: function branchCourses(payload) {
    var branch_courses = payload.branch_courses,
      linked = payload.linked;
    return {
      branchCourses: branch_courses.map(function (course) {
        return Mapper.branchCourse(course, linked);
      })
    };
  },
  createCoursePurchaseBasketRequest: function createCoursePurchaseBasketRequest(id) {
    return {
      items: [{
        type: 'COURSE',
        id: id
      }]
    };
  },
  purchaseBasketItem: function purchaseBasketItem(payload) {
    var id = payload.id,
      type = payload.type;
    if (type === 'COURSE') {
      return {
        id: id
      };
    }
    return null;
  },
  purchaseBasket: function purchaseBasket(payload) {
    var _items$map$filter, _items$map;
    var id = payload.id,
      items = payload.items,
      totalPrice = payload.total_price,
      payments = payload.payments;
    return {
      id: id,
      items: (_items$map$filter = items === null || items === void 0 || (_items$map = items.map(function (item) {
        return Mapper.purchaseBasketItem(item);
      })) === null || _items$map === void 0 ? void 0 : _items$map.filter(Boolean)) !== null && _items$map$filter !== void 0 ? _items$map$filter : [],
      payments: payments === null || payments === void 0 ? void 0 : payments.map(function (payment) {
        return Mapper.reservationPayment(payment);
      }),
      totalPrice: totalPrice
    };
  },
  basketStatus: function basketStatus(payload) {
    var status = payload.status;
    return Object.values(BasketStatusEnum).includes(status) ? status : BasketStatusEnum.UNKNOWN;
  }
};
function capitalize(title) {
  if (title == null) return '';
  return title.charAt(0).toUpperCase() + title.slice(1);
}
export function generateRandomId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}
export default Mapper;