import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { Dimensions, Keyboard, TextInput, View } from 'react-native';
import Localize from '~/common/Localize';
import { Body2, Container, InfoType, Theme, themed } from '~/styles/Theme';
import MainButton, { MainButtonType } from './MainButton';
var LongTextInputForm = function LongTextInputForm(props) {
  var _props$placeholder;
  var _useState = useState(''),
    text = _useState[0],
    setText = _useState[1];
  var _useState2 = useState(0),
    keyboardHeight = _useState2[0],
    setKeyboardHeight = _useState2[1];
  useEffect(function () {
    Keyboard.addListener('keyboardDidShow', keyboardDidShow);
  }, []);
  function keyboardDidShow(e) {
    if (e && e.endCoordinates && e.endCoordinates.height) {
      setKeyboardHeight(e.endCoordinates.height);
    }
  }
  function hasValidInputFields() {
    return props.starRating != null && props.starRating > 0 || !isEmpty(text);
  }
  function onChangeText(text) {
    if (props.onChangeText) {
      props.onChangeText(text);
    }
    setText(text);
  }
  var submissionEnabled = hasValidInputFields() && props.isSubmissionEnabled;
  return __jsx(Contain, null, __jsx(Message, null, props.info), __jsx(Input, {
    value: text,
    onChangeText: onChangeText,
    testID: props.testID,
    maxLength: props.maxLength,
    multiline: true,
    keyboardHeight: keyboardHeight,
    placeholder: (_props$placeholder = props.placeholder) !== null && _props$placeholder !== void 0 ? _props$placeholder : '',
    placeholderTextColor: Theme(props).colors.neutral3
  }), __jsx(View, {
    style: {
      height: 30
    }
  }), __jsx(MainButton, {
    type: MainButtonType.main,
    infoType: InfoType.info,
    onPress: function onPress() {
      Keyboard.dismiss();
      props.onSubmission(text);
    },
    disable: !submissionEnabled,
    testID: "sendTextButton"
  }, props.title), __jsx(View, {
    style: {
      height: 10
    }
  }), __jsx(MainButton, {
    type: MainButtonType.tally,
    onPress: props.onSkip,
    style: {
      color: Theme(props).colors.neutral7,
      backgroundColor: 'transparent',
      borderWidth: 1
    },
    testID: "skipFeedbackButton"
  }, Localize('feedback.skip')));
};
export default LongTextInputForm;
var Contain = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 600px\n"])));
var MAGIC_NUMBER = 280;
var Input = themed(TextInput)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  min-height: 60px;\n  border-bottom-width: 2px;\n  font-size: 15px;\n  font-family: ", "\n  line-height: ", "px\n  color: ", "\n  padding: 5px\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.navBarColor);
}, function (props) {
  return "max-height: ".concat(Dimensions.get('window').height - props.keyboardHeight - MAGIC_NUMBER, "px");
}, function (props) {
  return Theme(props).typography.body1.family;
}, function (props) {
  return Theme(props).typography.body1.lineHeight;
}, function (props) {
  return Theme(props).colors.neutral7;
});
var Message = themed(Body2)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding-top: 10px\n  padding-bottom: 10px \n  text-align: center\n"])));