import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import { LocalImageEnum, localImageSource } from '~/common/Helpers';
import QuickImage from '~/components/QuickImage';
import { Container, themed } from '~/styles/Theme';
var StarRating = function StarRating(_ref) {
  var onStarRateChange = _ref.onStarRateChange;
  var _useState = useState(0),
    rate = _useState[0],
    setRate = _useState[1];
  useEffect(function () {
    onStarRateChange(rate);
  }, [rate]);
  return __jsx(StarContainer, {
    testID: "starRating"
  }, __jsx(Star, {
    selected: rate > 0,
    onPress: function onPress() {
      return setRate(1);
    },
    testID: 'star1'
  }), __jsx(Star, {
    selected: rate > 1,
    onPress: function onPress() {
      return setRate(2);
    },
    testID: 'star2'
  }), __jsx(Star, {
    selected: rate > 2,
    onPress: function onPress() {
      return setRate(3);
    },
    testID: 'star3'
  }), __jsx(Star, {
    selected: rate > 3,
    onPress: function onPress() {
      return setRate(4);
    },
    testID: 'star4'
  }), __jsx(Star, {
    selected: rate > 4,
    onPress: function onPress() {
      return setRate(5);
    },
    testID: 'star5'
  }));
};
var Star = function Star(_ref2) {
  var selected = _ref2.selected,
    onPress = _ref2.onPress,
    testID = _ref2.testID;
  return __jsx(Pressable, {
    onPress: onPress,
    testID: testID
  }, __jsx(QuickImage, {
    source: localImageSource(selected ? LocalImageEnum.feedbackStarOn : LocalImageEnum.feedbackStarOff),
    style: {
      width: 40,
      height: 40
    },
    testID: selected ? 'starOn' : 'starOff'
  }));
};
export default StarRating;
var StarContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\n  flex-direction: row\n  justify-content: flex-start\n  align-items: baseline\n  margin: 0 25px 10px 25px;\n"])));