import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
import { Image, Pressable } from 'react-native';
import { useRouter } from 'solito/router';
import { LocalImageEnum, localImageSource } from '~/common/Helpers';
import Localize from '~/common/Localize';
import useSentry from '~/common/hooks/sentry/useSentry';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { Body3, Container, SubTitle2, Theme, themed, WhiteContainer } from '~/styles/Theme';
export function NotFoundScreen(props) {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var sentry = useSentry();
  var hideHomeButton = props.hideHomeButton;
  useEffect(function () {
    sentry.captureMessage('Showing 404 page', {
      url: window.location.href,
      referrer: document.referrer
    });
  }, []);
  return __jsx(WhiteContainer, null, __jsx(MiddleView, {
    testID: "notFoundScreen"
  }, __jsx(Image, {
    source: localImageSource(LocalImageEnum.image404),
    style: {
      flex: 1,
      aspectRatio: 2.5,
      resizeMode: 'contain',
      width: '100%',
      height: '100%'
    }
  }), __jsx(MessageContainer, {
    testID: "errorMessage404"
  }, __jsx(SubTitle2, {
    style: {
      marginBottom: 8
    }
  }, Localize('notFound.errorTitle')), __jsx(Body3, null, Localize('notFound.errorMessage'))), hideHomeButton !== true ? __jsx(Button, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Home));
    },
    testID: "backToHomeButton"
  }, __jsx(SubTitle2, null, Localize('notFound.buttonText'))) : null));
}
export default NotFoundScreen;
var MiddleView = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  justify-content: center;\n  align-items: center;\n  padding: 54px\n"])));
var MessageContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: 16px\n  margin-bottom: 16px\n  align-items: center\n"])));
var Button = themed(Pressable)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background: ", "\n  flex-direction: row\n  justify-content: center\n  align-self: center\n  align-items: center\n  padding: 8px 24px\n  width: 164px\n  height: 40px\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08)\n  border-radius: 8px\n  border-width: 1px\n  border-color: #BAC2D0\n  border-style: solid\n"])), function (props) {
  return Theme(props).colors.neutral;
});