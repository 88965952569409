import React from "react";
var __jsx = React.createElement;
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { TEN_MINS_IN_MILLISECONDS } from '~/api/models/Constants';
import { getSubdomainFromCurrentPath, valueForWebOnly } from './Helpers';
import store, { createReducers } from './store/store';
export var queryCache = new QueryCache();
export var queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: TEN_MINS_IN_MILLISECONDS,
      retry: 2
    }
  }
});
export function Provider(_ref) {
  var children = _ref.children;
  var _useState = useState(),
    persistor = _useState[0],
    setPersistor = _useState[1];
  useEffect(function () {
    var subdomain = getSubdomainFromCurrentPath();
    if (subdomain) {
      store.replaceReducer(createReducers(subdomain));
      setPersistor(persistStore(store));
    }
  }, []);
  return persistor != null ? __jsx(ReduxProvider, {
    store: store
  }, __jsx(PersistGate, {
    loading: null,
    persistor: persistor
  }, __jsx(QueryClientProvider, {
    client: queryClient
  }, valueForWebOnly(__jsx(ReactQueryDevtools, {
    initialIsOpen: false
  })), children))) : null;
}