import React from "react";
var __jsx = React.createElement;
import { Pressable, Linking } from 'react-native';
export var onLinkPress = function onLinkPress(url) {
  if (!url) {
    console.warn('URL is not provided');
    return;
  }
  try {
    if (url.startsWith('mailto:')) {
      Linking.openURL(url);
    } else {
      Linking.canOpenURL(url).then(function (supported) {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.warn('Cannot open URL:', url);
        }
      })["catch"](function (err) {
        console.error('An error occurred:', err);
      });
    }
  } catch (err) {
    console.warn('Failed to open URL:', err);
  }
};
var OpenLink = function OpenLink(_ref) {
  var url = _ref.url,
    children = _ref.children,
    style = _ref.style,
    testID = _ref.testID;
  var getDynamicStyle = function getDynamicStyle(pressed) {
    return [{
      opacity: pressed ? 0.6 : 1
    }, style];
  };
  return __jsx(Pressable, {
    onPress: function onPress() {
      return onLinkPress(url);
    },
    style: function style(_ref2) {
      var pressed = _ref2.pressed;
      return getDynamicStyle(pressed);
    },
    testID: testID
  }, children);
};
export default OpenLink;