import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createParam } from 'solito';
import { ThemeProvider } from 'styled-components/native';
import useSentry from '~/common/hooks/sentry/useSentry';
import { buildResourceWithVariables, settings } from './api/Resources';
import { isBranchOpen } from './api/models/ModelFunctions';
import { Env, isProduction } from './common/Env';
import { AnalyticsEvents } from './common/analytics/Analytics';
import useAnalytics from './common/hooks/analytics/useAnalytics';
import useAnalyticsTracking from './common/hooks/analytics/useAnalyticsTracking';
import useLocalization from './common/hooks/useLocalization';
import useUserMigration from './common/hooks/useUserMigration';
import useVipBooking from './common/hooks/useVipBooking';
import useVisualMode from './common/hooks/useVisualMode';
import { fetchResource } from './common/network/network';
import { resetApp, setEnv, setInitAppState, setIsWelcomeDisplayed } from './common/slices/app.slice';
import { fetchModels, selectBranch, selectBrand, selectIsLoadingModels } from './common/slices/model.slice';
import ClosedSalon from './components/ClosedSalon';
import ErrorGate from './components/ErrorGate';
import LoadingView from './components/LoadingView';
import FacebookPixelSetup from './components/web/FacebookPixelSetup';
import GoogleAnalyticsSetup from './components/web/GoogleAnalyticsSetup';
import Metatags from './components/web/Metatags';
import NotFoundScreen from './components/web/NotFoundScreen';
import { PageLayout } from './components/web/PageLayout';
import { buildTheme } from './styles/Theme';
export function AppWeb(props) {
  var Component = props.Component,
    pageProps = props.pageProps;
  var dispatch = useDispatch();
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var _useParam = useParam('subdomain'),
    _useParam2 = _slicedToArray(_useParam, 1),
    subdomain = _useParam2[0];
  var branch = useSelector(selectBranch);
  var brand = useSelector(selectBrand);
  var isLoadingModels = useSelector(selectIsLoadingModels);
  var isVip = useVipBooking();
  var sentry = useSentry();
  var analytics = useAnalytics();
  var isLocaleReady = useLocalization();
  var visualMode = useVisualMode();
  var isEmbedded = useMemo(function () {
    return window.self !== window.top;
  }, [window]);

  // ####################################################
  // SUBDOMAIN SETUP
  // ####################################################

  useEffect(function () {
    dispatch(setEnv(isProduction() ? Env.Prod : Env.QA));
  }, []);
  var _useQuery = useQuery([settings.path, subdomain], function () {
      if ((branch === null || branch === void 0 ? void 0 : branch.domainName) !== subdomain) {
        if ((branch === null || branch === void 0 ? void 0 : branch.domainName) != null) {
          // tracking how many times user switch to a different salons on the same browser
          analytics.logAnalyticsEvent(AnalyticsEvents.SwitchSalonAppReset);
        }
        dispatch(resetApp());
      }
      return fetchResource(buildResourceWithVariables(settings, {
        subdomain: subdomain
      }));
    }, {
      enabled: subdomain != null
    }),
    data = _useQuery.data,
    isLoading = _useQuery.isLoading,
    isError = _useQuery.isError;
  useEffect(function () {
    if (!data || !isBranchOpen(data, isVip)) {
      return;
    }
    var branchId = data.branchId,
      businessId = data.businessId;
    dispatch(setInitAppState({
      displayName: '',
      businessId: businessId,
      bundledBusinessId: businessId,
      env: isProduction() ? Env.Prod : Env.QA,
      developerMode: false,
      branchId: branchId,
      isEmbedded: isEmbedded,
      isVip: isVip,
      isMobileSidebarOpen: false
    }));
    dispatch(fetchModels(visualMode));
  }, [data, isEmbedded, isVip, visualMode]);

  // ################################################
  // ####### 2. UPDATE THE THEME WITH NEW BRAND #####
  // ################################################

  var theme = useMemo(function () {
    return buildTheme(brand);
  }, [brand]);
  useEffect(function () {
    dispatch(setIsWelcomeDisplayed(true));
  }, []);
  useAnalyticsTracking();
  useUserMigration();
  if (isError) {
    sentry.captureMessage('Branch Settings for this subdomain returned 404 not found', {
      subdomain: subdomain
    });
    return __jsx(NotFoundScreen, {
      hideHomeButton: true
    });
  }
  if (data && !isBranchOpen(data, isVip)) {
    return __jsx(ClosedSalon, null);
  }
  var displayLoading = !isLocaleReady || (isLoading || isLoadingModels) && props.Component !== NotFoundScreen;
  return __jsx(ThemeProvider, {
    theme: theme
  }, __jsx(ErrorGate, null, __jsx(GoogleAnalyticsSetup, null), __jsx(FacebookPixelSetup, null), displayLoading ? __jsx(View, {
    style: {
      minHeight: '100%'
    }
  }, __jsx(LoadingView, {
    title: "..."
  })) : __jsx(React.Fragment, null, __jsx(Metatags, {
    pageProps: props
  }), __jsx(PageLayout, {
    pageProps: props
  }, __jsx(Component, pageProps)))));
}
export default AppWeb;