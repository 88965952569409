import React from "react";
var __jsx = React.createElement;
import { useDispatch, useSelector } from 'react-redux';
import { fetchModels, selectModelError } from '~/common/slices/model.slice';
import ErrorScreen from './ErrorScreen';

// The error gate exists to support the initial setup of the app via Saga.
// Without this component, errors that happen at Saga level are not propagated to the user.
export function ErrorGate(_ref) {
  var children = _ref.children;
  var modelError = useSelector(selectModelError);
  var dispatch = useDispatch();
  function retry() {
    if (modelError) {
      dispatch(fetchModels());
    }
  }
  return modelError ? __jsx(ErrorScreen, {
    message: modelError.message,
    onPress: retry
  }) : __jsx(React.Fragment, null, children);
}
export default ErrorGate;