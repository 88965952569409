import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { WEB_CONTAINER_WIDTH, WEB_FOOTER_HEIGHT } from '~/api/models/Constants';
import Localize from '~/common/Localize';
import useOnScreen from '~/common/hooks/useOnScreen';
import { selectFeedbackButtonSafeHeight } from '~/common/slices/app.slice';
import { selectFeatureFlag, FeatureFlagsEnum } from '~/common/slices/model.slice';
import { RouteName } from '~/navigation/Routes';
import { Container, SubTitle2, Theme, themed } from '~/styles/Theme';
import Feedback, { FeedbackSource } from './Feedback';
import WebModal from './WebModal';
// This adds some extra width to the container so that in some medium viewports the FAB is not
// overlapping with the tally
var WEB_CONTAINER_EXTRA_WIDTH_THRESHOLD = 170;
var DEFAULT_BOTTOM = 5;
var FAB_MARGIN = 5;
export function FeedbackFabButton(_ref) {
  var _useSelector;
  var isHomeScreen = _ref.isHomeScreen,
    footerRef = _ref.footerRef,
    displayName = _ref.displayName;
  var FEEDBACK_FAB_BLACKLIST = [RouteName.Feedback];
  var isFooterVisible = useOnScreen(footerRef);
  var _useState = useState(window.innerWidth),
    viewportWidth = _useState[0],
    setViewportWidth = _useState[1];
  var _useState2 = useState(false),
    modalVisible = _useState2[0],
    setModalVisible = _useState2[1];
  var feedbackButtonFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.FeedbackButton));
  var showFab = !FEEDBACK_FAB_BLACKLIST.includes(displayName) && feedbackButtonFeatureFlag;
  var height = (_useSelector = useSelector(selectFeedbackButtonSafeHeight)) !== null && _useSelector !== void 0 ? _useSelector : 0;
  var _useState3 = useState(DEFAULT_BOTTOM),
    bottom = _useState3[0],
    setBottom = _useState3[1];
  useEffect(function () {
    var handleResize = function handleResize() {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(function () {
    if (height > 0 && viewportWidth <= WEB_CONTAINER_WIDTH + WEB_CONTAINER_EXTRA_WIDTH_THRESHOLD) {
      var distanceFromBottom = height + FAB_MARGIN;
      if (isFooterVisible) {
        distanceFromBottom += WEB_FOOTER_HEIGHT;
      }
      setBottom(distanceFromBottom);
    } else {
      var _distanceFromBottom = DEFAULT_BOTTOM + FAB_MARGIN;
      if (isFooterVisible) {
        _distanceFromBottom += WEB_FOOTER_HEIGHT;
      }
      setBottom(_distanceFromBottom);
    }
  }, [height, isFooterVisible, viewportWidth]);
  var handlePress = function handlePress() {
    setModalVisible(true);
  };
  function onModalClose() {
    setModalVisible(false);
  }
  if (!showFab) return null;
  return height >= 0 ? __jsx(FabContainer, {
    bottom: bottom
  }, __jsx(FabButton, {
    onPress: handlePress,
    isHomeScreen: isHomeScreen,
    testID: "FeedbackFabButton"
  }, __jsx(Icon, {
    name: "message-circle",
    size: 24,
    color: "white"
  }), isHomeScreen && __jsx(FabText, null, Localize('feedback.give'))), __jsx(WebModal, {
    isVisible: modalVisible,
    hideCloseButton: true,
    onClose: onModalClose
  }, __jsx(View, {
    style: {
      width: '100%'
    }
  }, __jsx(Feedback, {
    source: FeedbackSource.FAB,
    onModalClose: onModalClose
  })))) : null;
}
export default FeedbackFabButton;
var FabContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed\n  bottom: ", "px;\n  right: 32px\n  z-index: 999\n"])), function (_ref2) {
  var bottom = _ref2.bottom;
  return bottom;
});
var FabButton = themed(Pressable)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  background-color: #115E59;\n  justify-content: center;\n  align-items: center;\n  elevation: 4;\n  flex-direction: row\n"])), function (_ref3) {
  var isHomeScreen = _ref3.isHomeScreen;
  if (isHomeScreen) {
    return 'padding: 6px 16px; border-radius: 24px';
  } else {
    return 'padding: 8px; border-radius: 120px';
  }
});
var FabText = themed(SubTitle2)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-left: 10px\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral);
});