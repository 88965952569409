import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import React from 'react';
import { Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import Localize from '~/common/Localize';
import useSalonDisplayName from '~/common/hooks/useSalonDisplayName';
import { selectIsUserLoggedIn } from '~/common/slices/user.slice';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import useLoyaltySettings from '~/screens/loyalty/useLoyaltySettings';
import { Body2, Container, Theme, Title1, themed } from '~/styles/Theme';
export default function DesktopHeader(_ref) {
  var isLandingScreen = _ref.isLandingScreen,
    isPreviewScreen = _ref.isPreviewScreen;
  var salonDisplayName = useSalonDisplayName();
  var _useRouter = useRouter(),
    push = _useRouter.push;
  function HeaderMenu() {
    if (isPreviewScreen) return;
    return isLandingScreen ? __jsx(HomePageLink, null) : __jsx(DesktopHeaderMenu, null);
  }
  return __jsx(Bar, {
    testID: "desktopHeader"
  }, __jsx(BusinessNameButton, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Home));
    },
    testID: 'homeLink'
  }, __jsx(BusinessName, {
    numberOfLines: 2,
    ellipsizeMode: "tail"
  }, salonDisplayName)), __jsx(MainNav, null, __jsx(HeaderMenu, null)));
}
function HomePageLink() {
  var _useRouter2 = useRouter(),
    push = _useRouter2.push;
  return __jsx(HomePageLinkContainer, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Home));
    },
    testID: "visitWebsiteButton"
  }, __jsx(Body2, null, Localize('header.goToWebsite')), __jsx(Icon, {
    style: {
      flex: 1 / 5,
      textAlign: 'center',
      marginLeft: 8
    },
    name: "arrow-up-right",
    color: "#282F3C",
    size: 24
  }));
}
function DesktopHeaderMenu() {
  var _useRouter3 = useRouter(),
    push = _useRouter3.push;
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var _useLoyaltySettings = useLoyaltySettings(),
    showLoyaltyTab = _useLoyaltySettings.showLoyaltyTab;
  return __jsx(React.Fragment, null, __jsx(NavLink, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.AboutUs));
    }
  }, __jsx(NavLinkText, null, Localize('header.aboutUs'))), __jsx(NavLink, {
    testID: 'serviceHistory',
    onPress: function onPress() {
      isUserLoggedIn ? push(getRouteUrl(RouteName.Appointments)) : push(getRouteUrl(RouteName.Login, {
        onLogin: RouteName.Appointments
      }));
    }
  }, __jsx(NavLinkText, null, Localize('header.rebookAnAppointment'))), showLoyaltyTab && __jsx(NavLink, {
    testID: 'loyalty',
    onPress: function onPress() {
      isUserLoggedIn ? push(getRouteUrl(RouteName.Loyalty)) : push(getRouteUrl(RouteName.Login, {
        onLogin: RouteName.Loyalty
      }));
    }
  }, __jsx(NavLinkText, null, Localize('header.loyalty'))), __jsx(NavLink, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Account));
    },
    testID: 'accountLink'
  }, __jsx(NavLinkText, null, Localize('header.account'))));
}
var Bar = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  height: 72px\n  flex-direction: row;\n  justify-content: space-between\n  align-content: center\n  padding: 8px 32px\n"])), function (props) {
  return "background: ".concat(Theme(props).colors.neutral);
});
var BusinessNameButton = themed(Pressable)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: flex-start\n  justify-content: center\n  flex: 1\n"])));
var BusinessName = themed(Title1)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});
var HomePageLinkContainer = themed(Pressable)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n  align-items: center;\n  flex-direction: row;\n"])));
var NavLink = themed(Pressable)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin-left: 32px\n"])));
var NavLinkText = themed(Body2)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});
var MainNav = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n"])));