import { useSelector } from 'react-redux';
import { analyticsLogger } from '~/common/analytics/AnalyticsHelpers';
import { selectBranch, selectBusiness } from '~/common/slices/model.slice';

/**
 * To get the maximum detail in reports, log the recommended events that make sense for your app and their prescribed parameters.
 * This also ensures that you benefit from the latest Google Analytics features as they become available.
 * Example of modern logging from Firebase https://firebase.google.com/docs/analytics/events?platform=web 
 * and GA4 https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
 * 
   ```
      logEvent(analytics, 'select_content', {
          content_type: 'image',
          content_id: 'P12453'
      });

      logEvent(analytics, 'goal_completion', { name: 'lever_puzzle'});

      gtag('event', 'screen_view', {
        'app_name': 'myAppName',
        'screen_name': 'Home'
      });
 *
 * 
 
 */

export var useAnalytics = function useAnalytics(customProviders) {
  var business = useSelector(selectBusiness);
  var branch = useSelector(selectBranch);
  if (!business || !branch) {
    return {
      logAnalyticsEvent: function logAnalyticsEvent() {
        // no operation
      },
      logPageViewEvent: function logPageViewEvent() {
        // no operation
      }
    };
  }
  return analyticsLogger(business, branch, customProviders);
};
export default useAnalytics;