import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Platform } from 'react-native';
import { BusinessSilo } from '~/api/models/BookingTypes';
import config from '../../resources/config.json';
import { Env } from './Env';
import { valueForPlatform } from './Helpers';
export var Config = {
  businessBaseURL: function businessBaseURL(env) {
    return env === Env.QA ? config.qa.businessBaseURL : config.prod.businessBaseURL;
  },
  voucherURL: function voucherURL(env) {
    return env === Env.QA ? config.qa.voucherURL : config.prod.voucherURL;
  },
  baseURL: function baseURL(env) {
    return env === Env.QA ? config.qa.baseURL : config.prod.baseURL;
  },
  source: function source() {
    return valueForPlatform({
      web: config.source.web,
      "native": Platform.OS === 'ios' ? config.source.ios : config.source.android
    });
  },
  airTableKey: function airTableKey() {
    return config.airTableKey;
  },
  airTableUserFeedbackURL: function airTableUserFeedbackURL(env) {
    return env === Env.QA ? config.qa.airTableUserFeedbackURL : config.prod.airTableUserFeedbackURL;
  },
  firebaseConfig: function firebaseConfig(env) {
    var firebaseConfig = env === Env.QA ? config.qa.firebaseConfig : config.prod.firebaseConfig;
    return _objectSpread({
      apiKey: config.firebaseApiKey
    }, firebaseConfig);
  },
  leadsURL: function leadsURL(env, silo) {
    if (env === Env.QA) return config.qa.leads.dev.url;
    return silo === BusinessSilo.US ? config.prod.leads.us.url : config.prod.leads.eu.url;
  },
  leadsLambdaApiKey: function leadsLambdaApiKey(env, silo) {
    if (env === Env.QA) return config.qa.leads.dev.apiKey;
    return silo === BusinessSilo.US ? config.prod.leads.us.apiKey : config.prod.leads.eu.apiKey;
  }
};