import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { Pressable } from 'react-native';
import useSalonContactInfo from '~/common/hooks/useSalonContactInfo';
import { Body1, Theme, Title2, themed } from '~/styles/Theme';
import Localize from '../common/Localize';
import EmptySearch from './EmptySearch';
import { onLinkPress } from './OpenLink';
var ClosedSalon = function ClosedSalon() {
  var contactInfo = useSalonContactInfo();
  var callSalonCTA = null;
  if (contactInfo.formattedPhone && contactInfo.phoneURI) {
    callSalonCTA = __jsx(Pressable, {
      onPress: function onPress() {
        onLinkPress(contactInfo.phoneURI);
      }
    }, __jsx(Body, {
      style: {
        paddingTop: 30
      }
    }, Localize('closed.callUs')), __jsx(PhoneNumberTitle, null, contactInfo.formattedPhone));
  }
  return __jsx(EmptySearch, {
    title: Localize('closed.title'),
    body: Localize('closed.message'),
    testID: "closedSalonEmptySearch"
  }, callSalonCTA);
};
export default ClosedSalon;
var Body = themed(Body1)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  text-align: center;\n"])));
var PhoneNumberTitle = themed(Title2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-align: center;\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.main);
});