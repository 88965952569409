import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { Image, View } from 'react-native';
import { LocalImageEnum, localImageSource } from '~/common/Helpers';
import { Body1, Theme, Title1, themed } from '~/styles/Theme';
var EmptySearch = function EmptySearch(props) {
  return __jsx(View, {
    style: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 20,
      height: '100%'
    },
    testID: props.testID
  }, __jsx(View, {
    style: {
      paddingRight: 30
    }
  }, __jsx(Image, {
    style: {
      width: 250,
      height: 250,
      resizeMode: 'contain',
      marginBottom: 50
    },
    source: localImageSource(LocalImageEnum.emptyChair)
  })), __jsx(Title, null, props.title), __jsx(Body, null, props.body), props.children);
};
export default EmptySearch;
var Title = themed(Title1)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  text-align: center;\n  ", ";\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});
var Body = themed(Body1)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-align: center;\n"])));