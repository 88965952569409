var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import Script from 'next/script';
import React from 'react';
import { useSelector } from 'react-redux';
import { bootstrap, buildResourceWithVariables } from '~/api/Resources';
import { FACEBOOK_APP_ID, FACEBOOK_PIXEL_ID } from '~/common/analytics/Analytics';
import useSentry from '~/common/hooks/sentry/useSentry';
import { fetchResource } from '~/common/network/network';
import { selectBranch } from '~/common/slices/model.slice';
export var FacebookPixelSetup = function FacebookPixelSetup() {
  var branch = useSelector(selectBranch);
  var sentry = useSentry();
  var _useQuery = useQuery([bootstrap.path, branch === null || branch === void 0 ? void 0 : branch.id], function () {
      return fetchResource(buildResourceWithVariables(bootstrap, {
        subdomain: branch === null || branch === void 0 ? void 0 : branch.domainName
      }));
    }, {
      enabled: branch != null
    }),
    data = _useQuery.data,
    isError = _useQuery.isError,
    isLoading = _useQuery.isLoading;
  if (isLoading) {
    return null;
  }
  if (isError) {
    sentry.captureMessage('Error fetching bootstrap info while setting up Facebook Pixel');
    return null;
  }
  var facebookPixelId = data.facebookPixelId,
    facebookAppId = data.facebookAppId,
    facebookLocale = data.facebookLocale;
  return __jsx(React.Fragment, null, facebookAppId != null && __jsx(Script, {
    id: FACEBOOK_APP_ID,
    strategy: "lazyOnload",
    dangerouslySetInnerHTML: {
      __html: "\n              window.fbAsyncInit = function() {\n                FB.init({\n                    appId: ".concat(facebookAppId, ",\n                    cookie: true,\n                    xfbml: false,\n                    version: 'v2.10'\n                });\n              };\n\n              (function(d, s, id){\n                var js, fjs = d.getElementsByTagName(s)[0];\n                if (d.getElementById(id)) {return;}\n                js = d.createElement(s); js.id = id;\n                js.src = \"//connect.facebook.net/").concat(facebookLocale, "/sdk.js\";\n                fjs.parentNode.insertBefore(js, fjs);\n              }(document, 'script', 'facebook-jssdk'));\n        ")
    }
  }), facebookPixelId != null && __jsx(Script, {
    id: FACEBOOK_PIXEL_ID,
    strategy: "lazyOnload",
    dangerouslySetInnerHTML: {
      __html: "\n            !function(f,b,e,v,n,t,s)\n            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n            n.queue=[];t=b.createElement(e);t.async=!0;\n            t.src=v;s=b.getElementsByTagName(e)[0];\n            s.parentNode.insertBefore(t,s)}(window, document,'script',\n            'https://connect.facebook.net/en_US/fbevents.js');\n            fbq('init', '".concat(facebookPixelId, "');\n        ")
    }
  }));
};
export default FacebookPixelSetup;