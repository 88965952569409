import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import React from "react";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Button, Dimensions, Modal, Pressable, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { WEB_CONTAINER_WIDTH } from '~/api/models/Constants';
import { isWeb } from '~/common/Env';
import Localize from '~/common/Localize';
import { Container, SubTitle1, themed, useAppTheme } from '~/styles/Theme';
var DEFAULT_WIDTH = '60%';
var WebModal = function WebModal(props) {
  var theme = useAppTheme();
  var children = props.children,
    isVisible = props.isVisible,
    onClose = props.onClose,
    style = props.style,
    hideCloseButton = props.hideCloseButton,
    title = props.title;
  var defaultStyle = {
    width: isWeb() && Dimensions.get('window').width > WEB_CONTAINER_WIDTH ? "".concat(WEB_CONTAINER_WIDTH * 0.9, "px") : "".concat(Dimensions.get('window').width * 0.9, "px"),
    maxWidth: "".concat(WEB_CONTAINER_WIDTH, "px")
  };
  return __jsx(Modal, {
    testID: 'webModal',
    onRequestClose: onClose,
    transparent: true,
    visible: isVisible
  }, __jsx(BlurContainer, {
    onPress: onClose
  }, __jsx(ModalContainer, {
    style: _objectSpread(_objectSpread({}, defaultStyle), style)
  }, __jsx(TitleContainer, null, title ? __jsx(SubTitle1, null, title) : null, __jsx(Pressable, {
    onPress: onClose,
    style: {
      marginLeft: 'auto'
    },
    testID: 'webModalXButton'
  }, __jsx(Icon, {
    name: "x-circle",
    color: "#616c7f",
    size: 25
  }))), __jsx(ScrollView, {
    contentContainerStyle: {
      flex: 1
    },
    style: {
      width: '100%'
    }
  }, children, !hideCloseButton && __jsx(React.Fragment, null, __jsx(Gap, null), __jsx(DarkContainer, null, __jsx(Button, {
    testID: 'webModalCloseButton',
    onPress: onClose,
    title: Localize('global.close'),
    color: theme === null || theme === void 0 ? void 0 : theme.colors.main
  })))))));
};
export default WebModal;
var Gap = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 20px\n"])));
var DarkContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 20px\n"])));
var BlurContainer = themed(Pressable)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex: 1\n  background-color: 'rgba(0, 0, 0, 0.5)'\n"])));

// This needs to be a pressable to clear the BlurContainer pressable onPress
var ModalContainer = themed(Pressable)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  justifyContent: flex-start;\n  backgroundColor: white;\n  borderColor: #c8ccd7;\n  borderRadius: 8px;\n  min-height: 100px;\n  max-height: 80%;\n  width: ", ";\n  margin: auto;\n  shadow-color: gray;\n  shadow-offset: 2px 2px;\n  shadow-opacity: 0.2;\n  shadow-radius: 4px;\n  border-width: 1px;\n"])), function (props) {
  var _props$style$width, _props$style;
  return (_props$style$width = (_props$style = props.style) === null || _props$style === void 0 ? void 0 : _props$style.width) !== null && _props$style$width !== void 0 ? _props$style$width : DEFAULT_WIDTH;
});
var TitleContainer = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  padding: 10px\n"])));