// moment
import moment from 'moment';
import MomentTz from 'moment-timezone';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-au';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/nl';
import 'moment/locale/sv';
import ReactMoment from 'react-moment';
import { Text } from 'react-native';
import { LocaleConfig } from 'react-native-calendars';
import * as RNLocalize from 'react-native-localize';
import { isWeb } from './Env';
import AppI18n from './i18n';

// we dont import en-us because that's the default on moment and en-us does not exist, it is just en
export var getDeviceLocale = function getDeviceLocale() {
  var languageTag = 'en';
  if (!isWeb() || typeof window !== 'undefined') {
    languageTag = RNLocalize.getLocales().length > 0 ? RNLocalize.getLocales()[0].languageTag : 'en';
  }
  return languageTag;
};

// *************************
// Setup of Moment (Date and Time)
// *************************

// Moment handles date & time display.
// There are 2 things that affect how date and time are displayed:
// 1. Timezone: must use the Branch timezone as the booking time refers to that specific location.
// 2. Locale: decision is to use the Branch locale for consistency with the timezone
// (for locale both Branch or Device are viable options from a customer point of view)
//
// IMPORTANT: moment only supports the locales that are manually imported therefore the momentLocale
// function must be used all the time before setting the locale manually
// TODO: move away from Moment in favour of Luxon or other

export var defaultMomentLocale = 'en-gb';
export var supportedMomentLocale = ['da', 'de', 'en', 'en-au', 'en-gb', 'en-ie', 'fi', 'fr', 'fr-ca', 'nl', 'sv'];
export var momentLocale = function momentLocale(locale) {
  if (typeof locale !== 'string') {
    return defaultMomentLocale;
  }
  locale = locale.toLowerCase();
  var findPerfectMatch = function findPerfectMatch(locale) {
    return supportedMomentLocale.includes(locale) ? locale : null;
  };
  var findPartialMatch = function findPartialMatch(locale) {
    return supportedMomentLocale.find(function (item) {
      return locale.startsWith(item);
    }) || null;
  };
  return findPerfectMatch(locale) || findPartialMatch(locale) || defaultMomentLocale;
};
ReactMoment.globalElement = Text;
ReactMoment.globalLocale = momentLocale(getDeviceLocale());
export var Localize = function Localize(name) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return AppI18n.t(name, params);
};

// TODO: All those globals should live in Redux State!

var _globalLocale;
var _globalTimezone;
export function setGlobalLocale(locale) {
  _globalLocale = [locale.lang, locale.country].join('-').toLowerCase();
}
export function getGlobalLocale() {
  return "".concat(_globalLocale);
}
export function setGlobalTimezone(timezone) {
  _globalTimezone = timezone;
  MomentTz.tz.setDefault(timezone);
}
export function getGlobalTimezone() {
  return _globalTimezone;
}
export function dateInSalonTimezone(date, locale) {
  var _getGlobalTimezone;
  var timeWithZone = MomentTz.utc(date).tz((_getGlobalTimezone = getGlobalTimezone()) !== null && _getGlobalTimezone !== void 0 ? _getGlobalTimezone : 'UTC');
  return locale != null ? timeWithZone.locale(locale) : timeWithZone;
}
export function stringDateInSalonTimezone(date, format, locale) {
  return dateInSalonTimezone(date, locale).format(format);
}

// Return the currency given the branch locale
export function getSalonCurrency() {
  var _globalLocale$split$, _globalLocale2;
  var country = (_globalLocale$split$ = (_globalLocale2 = _globalLocale) === null || _globalLocale2 === void 0 || (_globalLocale2 = _globalLocale2.split('-')[1]) === null || _globalLocale2 === void 0 ? void 0 : _globalLocale2.toUpperCase()) !== null && _globalLocale$split$ !== void 0 ? _globalLocale$split$ : undefined;
  if (!country) {
    return undefined;
  }
  if (['GB'].includes(country)) {
    return 'GBP';
  }
  if (['US', 'CA'].includes(country)) {
    return 'USD';
  }
  if (['ZA'].includes(country)) {
    return 'ZAR';
  }
  if (['SV'].includes(country)) {
    return 'SEK';
  }
  if (['DK'].includes(country)) {
    return 'DKK';
  }
  if (['CH'].includes(country)) {
    return 'CHF';
  }
  if (['IE', 'FI', 'FR', 'LU', 'AT', 'DE', 'NL'].includes(country)) {
    return 'EUR';
  }
  if (['AU'].includes(country)) {
    return 'AUD';
  }
  if (['AE'].includes(country)) {
    return 'AED';
  }
  return undefined;
}

// Returns the price with associated currency given the branch locale
export function LocalizedPrice(value, digits) {
  if (!_globalLocale) {
    console.warn('The Global Locale is not defined, price will not have currency');
  }
  var price = String(value.toFixed(digits ? digits : 2));
  if (_globalLocale.toLocaleLowerCase() === 'en-ie') {
    return "\u20AC".concat(price);
  }
  switch (getSalonCurrency()) {
    case 'GBP':
      return "\xA3".concat(price);
    case 'USD':
      return "$".concat(price);
    case 'ZAR':
      return "".concat(price, " R");
    case 'SEK':
      return "".concat(price, " kr");
    case 'DKK':
      return "".concat(price, " kr");
    case 'CHF':
      return "".concat(price, " CHF");
    case 'EUR':
      return "".concat(price, " \u20AC");
    case 'AUD':
      return "$".concat(price);
    case 'AED':
      return "\u062F.\u0625 ".concat(price);
    default:
      return price;
  }
}
export function LocalizedError(error) {
  switch (error.type) {
    case 'service_already_booked_this_day':
      return Localize('error.alreadyBooked');
    case 'min_days_between_sessions_not_exceeded':
      return Localize('error.minimum');
    case 'max_days_between_sessions_exceeded':
      return Localize('error.maximum');
    case 'inside_patch_policy_hours':
      return Localize('error.patchTestFailed');
    default:
      return error.message ? error.message : Localize('error.tryAgain');
  }
}
export var isTranslationMissing = function isTranslationMissing(key) {
  try {
    AppI18n.t(key, {
      missingBehavior: 'error'
    });
  } catch (error) {
    return true;
  }
  return false;
};
export function setupCalendarLocale() {
  // react native calendar
  LocaleConfig.locales = {
    custom: {
      monthNames: [Localize('home.month.JAN'), Localize('home.month.FEB'), Localize('home.month.MAR'), Localize('home.month.APR'), Localize('home.month.MAY'), Localize('home.month.JUN'), Localize('home.month.JUL'), Localize('home.month.AUG'), Localize('home.month.SEP'), Localize('home.month.OCT'), Localize('home.month.NOV'), Localize('home.month.DEC')],
      monthNamesShort: [Localize('home.month.JAN_S'), Localize('home.month.FEB_S'), Localize('home.month.MAR_S'), Localize('home.month.APR_S'), Localize('home.month.MAY_S'), Localize('home.month.JUN_S'), Localize('home.month.JUL_S'), Localize('home.month.AUG_S'), Localize('home.month.SEP_S'), Localize('home.month.OCT_S'), Localize('home.month.NOV_S'), Localize('home.month.DEC_S')],
      dayNames: [Localize('home.day.SU'), Localize('home.day.MO'), Localize('home.day.TU'), Localize('home.day.WE'), Localize('home.day.TH'), Localize('home.day.FR'), Localize('home.day.SA')],
      dayNamesShort: [Localize('home.day.SU_S'), Localize('home.day.MO_S'), Localize('home.day.TU_S'), Localize('home.day.WE_S'), Localize('home.day.TH_S'), Localize('home.day.FR_S'), Localize('home.day.SA_S')]
    }
  };
  LocaleConfig.defaultLocale = 'custom';
}
export function updateMomentLocale() {
  moment.updateLocale(momentLocale(getDeviceLocale()), {
    months: [Localize('home.month.JAN'), Localize('home.month.FEB'), Localize('home.month.MAR'), Localize('home.month.APR'), Localize('home.month.MAY'), Localize('home.month.JUN'), Localize('home.month.JUL'), Localize('home.month.AUG'), Localize('home.month.SEP'), Localize('home.month.OCT'), Localize('home.month.NOV'), Localize('home.month.DEC')],
    monthsShort: [Localize('home.month.JAN_S'), Localize('home.month.FEB_S'), Localize('home.month.MAR_S'), Localize('home.month.APR_S'), Localize('home.month.MAY_S'), Localize('home.month.JUN_S'), Localize('home.month.JUL_S'), Localize('home.month.AUG_S'), Localize('home.month.SEP_S'), Localize('home.month.OCT_S'), Localize('home.month.NOV_S'), Localize('home.month.DEC_S')]
  });
}
export default Localize;