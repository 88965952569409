import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useEffect, useMemo, useState } from 'react';
export default function useOnScreen(ref) {
  var _useState = useState(false),
    isIntersecting = _useState[0],
    setIntersecting = _useState[1];
  var observer = useMemo(function () {
    return new IntersectionObserver(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
        entry = _ref2[0];
      return setIntersecting(entry.isIntersecting);
    });
  }, [ref]);
  useEffect(function () {
    if (!ref.current) return;
    observer.observe(ref.current);
    return function () {
      return observer.disconnect();
    };
  }, []);
  return isIntersecting;
}