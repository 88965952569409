export var REQUIRES_FINISH_CATEGORY_ID = 'SAEAyodabE1LebYzWJyymQ'; // COLOR_CATEGORY_ID
export var IS_FINISH_CATEGORY_ID = 'OXRk1jSMnyGNEsRrjfe57A'; // CUT_AND_STYLE_CATEGORY_ID
export var SERVICE_GROUP_DIVIDER = '_option_';
export var SERVICE_LINK_DIVIDER = '_link_';
export var PAY_TYPE_BANCARD_CARD_CAPTURE = 'BANCARD_CARD_CAPTURE';
export var PAY_TYPE_STRIPE_CARD_PAYMENT = 'STRIPE_CARD_PAYMENT';
export var PAY_TYPE_STRIPE_CARD_CAPTURE = 'STRIPE_CARD_CAPTURE';
export var PAY_TYPE_NO_CARD_REQUIRED = 'NO_CARD_REQUIRED';
export var TEN_MINS_IN_MILLISECONDS = 60 * 10 * 1000;
export var MAX_ACTIVATION_STATUS_RETRIES = 40;
export var RETRY_MILLISECONDS_DELAY = 3000;

// network
export var ACCEPT_HEADER = 'application/vnd.phorest.me+json;version=1';
export var DEFAULT_TIMEOUT = 15000;
export var AVAILABILITY_TIMEOUT = 60000; // this is because of planner being very slow

// checkout
export var CHECKOUT_TIMEOUT_IN_SECONDS = 300;
export var PAYMENT_SDK_INIT_TIMEOUT = 6000;

// Branches
export var MIN_BRANCHES_FOR_SEARCH_BAR = 8;

// Layout
export var WEB_CONTAINER_WIDTH = 1024;
export var WEB_FOOTER_HEIGHT = 56;
export var QA_FACEBOOK_APP_ID = '1566965560183148';
export var GROUP_BOOKING_MAX_NUM_OF_PEOPLE = 20;