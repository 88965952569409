import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { ActivityIndicator } from 'react-native';
import { Container, themed, Body1, useAppTheme } from '~/styles/Theme';
var DEFAULT_COLOR = '#999999';
var LoadingView = function LoadingView(_ref) {
  var title = _ref.title,
    color = _ref.color;
  var theme = useAppTheme();
  var indicatorColor = color || (theme === null || theme === void 0 ? void 0 : theme.colors.main) || DEFAULT_COLOR;
  return __jsx(Wrapper, null, title ? __jsx(Title, {
    testID: "loadingTitle"
  }, title) : null, __jsx(ActivityIndicator, {
    color: indicatorColor,
    testID: "loadingView"
  }));
};
export default LoadingView;
var Title = themed(Body1)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 8px\n"])));
var Wrapper = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex: 1;\n  justify-content: center\n  align-items: center\n"])));