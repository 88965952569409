import React from "react";
var __jsx = React.createElement;
import Head from 'next/head';
import { AppWeb } from '~/AppWeb';
import { Provider } from '~/common/Provider';
import './styles.css';
function App(props) {
  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("meta", {
    name: "viewport",
    content: "width=device-width, user-scalable=no"
  })), __jsx("main", {
    className: "app"
  }, __jsx(Provider, null, __jsx(AppWeb, props))));
}
export default App;