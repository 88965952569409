import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumber } from '~/api/models/ModelFunctions';
import { selectBranch, selectBusiness } from '../slices/model.slice';
var useSalonContactInfo = function useSalonContactInfo() {
  var branch = useSelector(selectBranch);
  var business = useSelector(selectBusiness);
  var phone = useMemo(function () {
    var _branch$phone;
    var branchPhone = branch === null || branch === void 0 || (_branch$phone = branch.phone) === null || _branch$phone === void 0 ? void 0 : _branch$phone.trim();
    var hasValidPhone = branchPhone && branchPhone.length > 0;
    if (hasValidPhone) {
      var _business$locale;
      var country = (business === null || business === void 0 || (_business$locale = business.locale) === null || _business$locale === void 0 ? void 0 : _business$locale.country) || 'IE';
      return formatPhoneNumber(branchPhone, country);
    }
    return undefined;
  }, [branch, business]);
  var email = useMemo(function () {
    var _branch$email;
    var branchEmail = branch === null || branch === void 0 || (_branch$email = branch.email) === null || _branch$email === void 0 ? void 0 : _branch$email.trim();
    var hasValidEmail = branchEmail && branchEmail.length > 0;
    if (hasValidEmail) {
      return "mailto:".concat(branchEmail);
    }
    return undefined;
  }, [branch]);
  return {
    formattedPhone: phone === null || phone === void 0 ? void 0 : phone.formattedPhone,
    phoneURI: phone === null || phone === void 0 ? void 0 : phone.phoneURI,
    email: email
  };
};
export default useSalonContactInfo;