import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from '@reduxjs/toolkit';
import { resetApp } from './app.slice';
var reducerName = 'purchase';
export var initialState = {
  isLoading: false
};
export var purchaseSlice = createSlice({
  name: reducerName,
  initialState: initialState,
  reducers: {
    startNewPurchaseBasket: function startNewPurchaseBasket() {
      return _objectSpread({}, initialState);
    },
    addPurchaseItem: function addPurchaseItem(state, action) {
      state.item = action.payload;
    },
    setPurchaseIsLoading: function setPurchaseIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPurchaseError: function setPurchaseError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    createPurchaseBasket: function createPurchaseBasket(state) {
      // Saga
      state.isLoading = true;
      state.error = undefined;
    },
    setPurchaseBasket: function setPurchaseBasket(state, action) {
      state.basket = action.payload;
      state.isLoading = false;
    },
    checkBasketStatus: function checkBasketStatus(state, action) {
      // Saga
    },
    setPurchaseHasSucceeded: function setPurchaseHasSucceeded(state, action) {
      state.hasSucceeded = action.payload;
    }
  },
  extraReducers: function extraReducers(builder) {
    builder.addCase(resetApp, function () {
      return initialState;
    });
  }
});
var _purchaseSlice$action = purchaseSlice.actions,
  startNewPurchaseBasket = _purchaseSlice$action.startNewPurchaseBasket,
  addPurchaseItem = _purchaseSlice$action.addPurchaseItem,
  setPurchaseError = _purchaseSlice$action.setPurchaseError,
  createPurchaseBasket = _purchaseSlice$action.createPurchaseBasket,
  setPurchaseBasket = _purchaseSlice$action.setPurchaseBasket,
  checkBasketStatus = _purchaseSlice$action.checkBasketStatus,
  setPurchaseHasSucceeded = _purchaseSlice$action.setPurchaseHasSucceeded;
export { startNewPurchaseBasket, addPurchaseItem, setPurchaseError, createPurchaseBasket, setPurchaseBasket, checkBasketStatus, setPurchaseHasSucceeded };
export var selectPurchaseItem = function selectPurchaseItem(state) {
  return state.purchase.item;
};
export var selectPurchaseIsLoading = function selectPurchaseIsLoading(state) {
  return state.purchase.isLoading;
};
export var selectPurchaseError = function selectPurchaseError(state) {
  return state.purchase.error;
};
export var selectPurchaseBasket = function selectPurchaseBasket(state) {
  return state.purchase.basket;
};
export var selectPurchaseHasSucceded = function selectPurchaseHasSucceded(state) {
  return state.purchase.hasSucceeded;
};
export default purchaseSlice.reducer;