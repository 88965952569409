import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import React from "react";
var __jsx = React.createElement;
import { useSelector } from 'react-redux';
import { WEB_FOOTER_HEIGHT } from '~/api/models/Constants';
import Localize from '~/common/Localize';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import { selectBranch } from '~/common/slices/model.slice';
import { themed, Container, Action1, Action2 } from '~/styles/Theme';
function Footer(_ref) {
  var footerRef = _ref.footerRef;
  var branch = useSelector(selectBranch);
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  return __jsx(FooterContainer, {
    ref: footerRef,
    style: isMobile && {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }, __jsx(Legal, null, __jsx(FooterLink, {
    href: branch === null || branch === void 0 ? void 0 : branch.termsUrl
  }, Localize('account.termsAndConditions')), __jsx(Divider, null, __jsx(FooterLink, null, "|")), __jsx(FooterLink, {
    href: (branch === null || branch === void 0 ? void 0 : branch.termsUrl) + '#cancellation'
  }, Localize('account.cancellationPolicy')), __jsx(Divider, null, __jsx(FooterLink, null, "|")), __jsx(FooterLink, {
    href: branch === null || branch === void 0 ? void 0 : branch.privacyPolicyUrl
  }, Localize('account.privacyPolicy'))), __jsx(PoweredBy, null, __jsx(PoweredByText, null, Localize('footer.poweredBy')), __jsx(FooterLink, {
    href: branch === null || branch === void 0 ? void 0 : branch.privacyPolicyUrl
  }, " Phorest")));
}
export default Footer;
var FooterContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center\n  padding: 24px 32px\n  background: #E7EBEF\n  height: ", "px\n  justify-content: space-between\n"])), WEB_FOOTER_HEIGHT);
var Legal = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center\n  padding: 0px\n"])));
var PoweredBy = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex-direction: row\n  justify-content: flex-end\n  align-items: center\n  padding: 0px\n"])));
var PoweredByText = themed(Action2)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: #7484A0\n"])));
var FooterLink = themed(Action1)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: #7484A0\n"])));
var Divider = themed(Action1)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-left: 8px\n  margin-right: 8px\n"])));