import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _PageConfigs, _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { useMemo } from 'react';
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import useMetaTags from '~/common/hooks/useMetatags';
import { selectIsUserLoggedIn } from '~/common/slices/user.slice';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { Container, SubTitle1, Theme, themed, useAppTheme } from '~/styles/Theme';
var PageConfigs = (_PageConfigs = {}, _defineProperty(_PageConfigs, RouteName.Home, {
  canGoBack: false,
  canGoAccount: true
}), _defineProperty(_PageConfigs, RouteName.CheckOutSuccess, {
  canGoBack: false,
  canGoAccount: false
}), _defineProperty(_PageConfigs, RouteName.ServiceSelection, {
  canGoBack: false,
  canGoAccount: true
}), _defineProperty(_PageConfigs, RouteName.Appointments, {
  canGoBack: true,
  goBackRoute: RouteName.Home,
  canGoAccount: false
}), _PageConfigs);
var EmbedNavigator = function EmbedNavigator(_ref) {
  var pageProps = _ref.pageProps;
  var theme = useAppTheme();
  var _useRouter = useRouter(),
    back = _useRouter.back,
    replace = _useRouter.replace,
    push = _useRouter.push;
  var _useMetaTags = useMetaTags(),
    getPageMetaTags = _useMetaTags.getPageMetaTags;
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var _useMemo = useMemo(function () {
      var _pageProps$Component, _pageConfig$canGoBack;
      var displayName = pageProps === null || pageProps === void 0 || (_pageProps$Component = pageProps.Component) === null || _pageProps$Component === void 0 ? void 0 : _pageProps$Component.displayName;
      var tags = getPageMetaTags(displayName);
      var pageConfig = displayName ? PageConfigs[displayName] : undefined;
      return {
        title: tags.title,
        canGoBack: (_pageConfig$canGoBack = pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.canGoBack) !== null && _pageConfig$canGoBack !== void 0 ? _pageConfig$canGoBack : true,
        goBackRoute: pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.goBackRoute,
        canGoAccount: pageConfig === null || pageConfig === void 0 ? void 0 : pageConfig.canGoAccount
      };
    }, [pageProps]),
    title = _useMemo.title,
    canGoBack = _useMemo.canGoBack,
    goBackRoute = _useMemo.goBackRoute,
    canGoAccount = _useMemo.canGoAccount;
  function onClose() {
    window.parent.postMessage(JSON.stringify({
      type: 'completed'
    }), '*');
  }
  function onBack() {
    goBackRoute ? replace(getRouteUrl(goBackRoute)) : back();
  }
  function onUser() {
    push(getRouteUrl(isUserLoggedIn ? RouteName.Account : RouteName.GuestAccount));
  }
  return __jsx(Bar, {
    testID: "embedNavigator"
  }, __jsx(View, {
    style: {
      flexDirection: 'row'
    }
  }, canGoBack && __jsx(Pressable, {
    onPress: onBack,
    testID: "backButton"
  }, __jsx(Icon, {
    name: "chevron-left",
    color: theme.colors.neutral7,
    size: 24
  })), canGoBack && canGoAccount && __jsx(View, {
    style: {
      minWidth: 20
    }
  }), canGoAccount && __jsx(Pressable, {
    onPress: onUser,
    testID: "accountButton"
  }, __jsx(Icon, {
    name: "user",
    color: theme.colors.neutral7,
    size: 24
  }))), __jsx(Title, {
    testID: "title"
  }, title), __jsx(Pressable, {
    onPress: onClose,
    testID: "closeButton"
  }, __jsx(Icon, {
    name: "x",
    color: theme.colors.neutral7,
    size: 24
  })));
};
export default EmbedNavigator;
var Bar = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  height: 56px\n  flex-direction: row;\n  justify-content: space-between\n  align-items: center\n  padding: 8px 20px\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var Title = themed(SubTitle1)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral7);
});