import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { loyaltySettings as loyaltySettingsResource, referralSettings as referralSettingsResource, user as userResource } from '~/api/Resources';
import { TreatcardStatusEnum } from '~/api/models/BookingTypes';
import { TEN_MINS_IN_MILLISECONDS } from '~/api/models/Constants';
import { fetchResource } from '~/common/network/network';
import { FeatureFlagsEnum, selectBranch, selectFeatureFlag } from '~/common/slices/model.slice';
import { selectIsUserLoggedIn, selectToken } from '~/common/slices/user.slice';
var useLoyaltySettings = function useLoyaltySettings() {
  var _user$loyaltyCard;
  var token = useSelector(selectToken);
  var branch = useSelector(selectBranch);
  var newLoyaltyTabFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.NewLoyaltyTab));
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var _useQuery = useQuery([loyaltySettingsResource.path, branch === null || branch === void 0 ? void 0 : branch.id, token], function () {
      return fetchResource(loyaltySettingsResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS,
      enabled: isUserLoggedIn
    }),
    loyaltySettings = _useQuery.data,
    isLoyaltySettingsLoading = _useQuery.isLoading,
    isLoyaltySettingsError = _useQuery.isError,
    refetchLoyaltySettings = _useQuery.refetch;
  var _useQuery2 = useQuery([referralSettingsResource.path, branch === null || branch === void 0 ? void 0 : branch.id, token], function () {
      return fetchResource(referralSettingsResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS,
      enabled: isUserLoggedIn
    }),
    referralSettings = _useQuery2.data,
    isReferralSettingsLoading = _useQuery2.isLoading,
    isReferralSettingsError = _useQuery2.isError,
    refetchReferralSettings = _useQuery2.refetch;
  var _useQuery3 = useQuery([userResource.path, branch === null || branch === void 0 ? void 0 : branch.id, token], function () {
      return fetchResource(userResource);
    }, {
      enabled: isUserLoggedIn
    }),
    user = _useQuery3.data,
    isUserLoading = _useQuery3.isLoading,
    isUserError = _useQuery3.isError,
    refetchUser = _useQuery3.refetch;

  /**
   * @deprecated This value will be removed in future versions. Use `showTreatCardPoints` instead.
   */
  var showLegacyTreatCardPoints = (branch === null || branch === void 0 ? void 0 : branch.treatcardEnableStatus) !== TreatcardStatusEnum.DISABLED;
  var isTreatCardOnlineEnabled = !!(loyaltySettings !== null && loyaltySettings !== void 0 && loyaltySettings.showTreatCardOnline);
  var showTreatCard = isTreatCardOnlineEnabled && !isEmpty(user === null || user === void 0 || (_user$loyaltyCard = user.loyaltyCard) === null || _user$loyaltyCard === void 0 ? void 0 : _user$loyaltyCard.serial);
  var showTreatCardPoints = isTreatCardOnlineEnabled && !!(loyaltySettings !== null && loyaltySettings !== void 0 && loyaltySettings.showTreatCardPoints);
  var showRewards = isTreatCardOnlineEnabled && !!(loyaltySettings !== null && loyaltySettings !== void 0 && loyaltySettings.showTreatCardRewards);
  var allowRewardsRedeem = isTreatCardOnlineEnabled && !!(loyaltySettings !== null && loyaltySettings !== void 0 && loyaltySettings.allowTreatCardRewardsRedeem);
  var showReferrals = !!(branch !== null && branch !== void 0 && branch.loyaltyReferralsFeatureEnabled) && !!(referralSettings !== null && referralSettings !== void 0 && referralSettings.enabled);

  // show loyalty tab if the feature flag is enabled, the user is logged in and
  // referrals is enabled or
  // online treat card feature is enabled and either treat card or rewards can be displayed
  var showLoyaltyTab = newLoyaltyTabFeatureFlag && isUserLoggedIn && (showReferrals || isTreatCardOnlineEnabled && (showTreatCard || showRewards));
  var refetch = function refetch() {
    if (refetchLoyaltySettings) {
      refetchLoyaltySettings();
    }
    if (refetchReferralSettings) {
      refetchReferralSettings();
    }
    if (refetchUser) {
      refetchUser();
    }
  };
  return {
    showLoyaltyTab: showLoyaltyTab,
    showTreatCard: showTreatCard,
    showRewards: showRewards,
    allowRewardsRedeem: allowRewardsRedeem,
    showReferrals: showReferrals,
    showTreatCardPoints: showTreatCardPoints,
    isLoading: isLoyaltySettingsLoading || isReferralSettingsLoading || isUserLoading,
    isError: isLoyaltySettingsError || isReferralSettingsError || isUserError,
    showLegacyTreatCardPoints: showLegacyTreatCardPoints,
    refetch: refetch
  };
};
export default useLoyaltySettings;