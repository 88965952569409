import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { createSlice } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import { resetApp } from './app.slice';
import { startNewCart } from './cart.slice';
var reducerName = 'client';
export var you = {
  id: 'guest_0',
  cartItems: []
};
export var initialState = {
  clients: [you]
};
function createClient(id, cartItems) {
  return {
    id: "guest_".concat(id),
    cartItems: cartItems !== null && cartItems !== void 0 ? cartItems : []
  };
}
export var clientSlice = createSlice({
  name: reducerName,
  initialState: initialState,
  reducers: {
    addClient: function addClient(state, action) {
      var clientId = state.clients.length;
      var newClient = createClient(clientId.toString(), action.payload.cartItemIds);
      state.clients.push(newClient);
    },
    addClients: function addClients(state, action) {
      var _action$payload = action.payload,
        cartItems = _action$payload.cartItemIds,
        numberOfClients = _action$payload.numberOfClients;
      var clientsToAdd = numberOfClients !== null && numberOfClients !== void 0 ? numberOfClients : 1;
      for (var i = 0; i < clientsToAdd; i++) {
        var clientId = state.clients.length;
        state.clients.push(createClient(clientId.toString(), cartItems));
      }
    },
    removeClient: function removeClient(state, action) {
      state.clients = state.clients.filter(function (client) {
        return action.payload === you || action.payload.id !== client.id;
      });
    },
    removeClients: function removeClients(state, action) {
      var clientIds = action.payload.filter(function (client) {
        return client !== you;
      }).map(function (client) {
        return client.id;
      });
      state.clients = state.clients.filter(function (client) {
        return !clientIds.includes(client.id);
      });
    },
    assignCartItemsToClients: function assignCartItemsToClients(state, action) {
      var _action$payload2 = action.payload,
        clients = _action$payload2.clients,
        cartItems = _action$payload2.cartItemIds;
      var clientIds = clients.map(function (client) {
        return client.id;
      });
      var existingClients = state.clients.filter(function (client) {
        return clientIds.includes(client.id);
      });
      existingClients.forEach(function (client) {
        return client.cartItems = uniq([].concat(_toConsumableArray(client.cartItems), _toConsumableArray(cartItems)));
      });
    },
    removeCartItemsFromClients: function removeCartItemsFromClients(state, action) {
      var _action$payload3 = action.payload,
        clients = _action$payload3.clients,
        cartItems = _action$payload3.cartItemIds;
      var existingClients = state.clients.filter(function (client) {
        return clients.map(function (c) {
          return c.id;
        }).includes(client.id);
      });
      existingClients.forEach(function (client) {
        return client.cartItems = client.cartItems.filter(function (item) {
          return !cartItems.includes(item);
        });
      });
    },
    incrementNumberOfClient: function incrementNumberOfClient() {
      // saga
    },
    decrementNumberOfClient: function decrementNumberOfClient() {
      // saga
    }
  },
  extraReducers: function extraReducers(builder) {
    builder.addCase(startNewCart, function () {
      return initialState;
    });
    builder.addCase(resetApp, function () {
      return initialState;
    });
  }
});
var _clientSlice$actions = clientSlice.actions,
  addClient = _clientSlice$actions.addClient,
  addClients = _clientSlice$actions.addClients,
  removeClient = _clientSlice$actions.removeClient,
  removeClients = _clientSlice$actions.removeClients,
  assignCartItemsToClients = _clientSlice$actions.assignCartItemsToClients,
  removeCartItemsFromClients = _clientSlice$actions.removeCartItemsFromClients,
  incrementNumberOfClient = _clientSlice$actions.incrementNumberOfClient,
  decrementNumberOfClient = _clientSlice$actions.decrementNumberOfClient;
export { addClient, addClients, removeClient, removeClients, assignCartItemsToClients, removeCartItemsFromClients, incrementNumberOfClient, decrementNumberOfClient };
export var selectClients = function selectClients(state) {
  return state.client.clients;
};
export var selectNumberOfClients = function selectNumberOfClients(state) {
  return state.client.clients.length;
};
export default clientSlice.reducer;