import React from "react";
var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import { bootstrap, buildResourceWithVariables } from '~/api/Resources';
import { ANALYTICS_GA4_CUSTOMER_GROUP_NAME, ANALYTICS_GA4_ID, ANALYTICS_GTM_ID } from '~/common/analytics/Analytics';
import useSentry from '~/common/hooks/sentry/useSentry';
import { fetchResource } from '~/common/network/network';
import { selectBranch } from '~/common/slices/model.slice';
export var GoogleAnalyticsSetup = function GoogleAnalyticsSetup() {
  var branch = useSelector(selectBranch);
  var sentry = useSentry();
  var _useQuery = useQuery([bootstrap.path, branch === null || branch === void 0 ? void 0 : branch.id], function () {
      return fetchResource(buildResourceWithVariables(bootstrap, {
        subdomain: branch === null || branch === void 0 ? void 0 : branch.domainName
      }));
    }, {
      enabled: branch != null
    }),
    data = _useQuery.data,
    isError = _useQuery.isError,
    isLoading = _useQuery.isLoading;
  if (branch == null || isLoading) {
    return null;
  }
  if (isError) {
    sentry.captureMessage('Error fetching bootstrap info while setting up Analytics');
    return null;
  }
  var gaTrackId = data.gaTrackId,
    gaDomain = data.gaDomain,
    gaPrimaryTrackId = data.gaPrimaryTrackId,
    gaPrimaryDomain = data.gaPrimaryDomain;
  if (!gaTrackId && !gaPrimaryTrackId) {
    return null;
  }

  // Primary tracking ID refers to the Customer Analytics Tracking ID
  if (isGoogleAnalytics4TrackId(gaPrimaryTrackId)) {
    // GA4 setup script for customer analytics
    return __jsx(Script, {
      id: ANALYTICS_GA4_ID,
      strategy: "lazyOnload",
      dangerouslySetInnerHTML: {
        __html: "\n        if (gtag != null && ".concat(gaPrimaryTrackId != null, "){\n          gtag('config', '").concat(gaPrimaryTrackId, "', { 'groups': '").concat(ANALYTICS_GA4_CUSTOMER_GROUP_NAME, "', 'cookie_flags': 'SameSite=None;Secure', 'allow_enhanced_conversion':true });\n        }\n        ")
      }
    });
  }
  if (isGoogleTagManagerTrackId(gaPrimaryTrackId)) {
    // GTM setup script for customer analytics
    return __jsx(Script, {
      id: ANALYTICS_GTM_ID,
      strategy: "lazyOnload",
      dangerouslySetInnerHTML: {
        __html: "\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','".concat(gaPrimaryTrackId, "');\n        ")
      }
    });
  }
  return (
    // UA setup script
    __jsx(Script, {
      id: "universal-analytics",
      strategy: "lazyOnload",
      dangerouslySetInnerHTML: {
        __html: "\n        if (".concat(gaTrackId != null, ") {\n          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n          })(window,document,'script','//www.google-analytics.com/analytics.js','ga');\n\n          if (").concat(gaPrimaryDomain != null, " && ").concat(gaPrimaryTrackId != null, ") {\n            ga('create', '").concat(gaTrackId, "', { 'cookieDomain': '").concat(gaDomain, "', 'name': 'bookingTracker' });\n            ga('create', '").concat(gaPrimaryTrackId, "', 'auto', { 'allowLinker': true });\n            ga('require', 'linker');\n            ga('linker:autoLink', ['").concat(gaPrimaryDomain, "']);\n          } else {\n            ga('create', '").concat(gaTrackId, "', { 'cookieDomain': '").concat(gaDomain, "' });\n          }\n\n          ga('require', 'displayfeatures');\n        }")
      }
    })
  );
};
function isGoogleAnalytics4TrackId(trackId) {
  return trackId ? trackId.startsWith('G-') : false;
}
function isGoogleTagManagerTrackId(trackId) {
  return trackId ? trackId.startsWith('GTM-') : false;
}
export default GoogleAnalyticsSetup;