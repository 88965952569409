import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch } from 'react-redux';
import { useRouter } from 'solito/router';
import useSalonDisplayName from '~/common/hooks/useSalonDisplayName';
import { setIsMobileSidebarOpen } from '~/common/slices/app.slice';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { useAppTheme, themed, Container, Theme, Title1 } from '~/styles/Theme';
export default function MobileHeader() {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var theme = useAppTheme();
  var dispatch = useDispatch();
  var salonDisplayName = useSalonDisplayName();
  return __jsx(Bar, {
    testID: "mobileHeader"
  }, __jsx(Pressable, {
    testID: "openSidebarButton",
    onPress: function onPress() {
      return dispatch(setIsMobileSidebarOpen(true));
    }
  }, __jsx(Icon, {
    name: "menu",
    color: theme.colors.main,
    size: 24
  })), __jsx(BusinessNameButton, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Home));
    },
    testID: "homeLink"
  }, __jsx(BusinessName, {
    numberOfLines: 2,
    ellipsizeMode: "tail"
  }, salonDisplayName)));
}
var Bar = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  height: 72px\n  flex-direction: row;\n  justify-content: space-between\n  padding: 8px 32px\n  align-items: center\n"])), function (props) {
  return "background: ".concat(Theme(props).colors.neutral);
});
var BusinessNameButton = themed(Pressable)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: center\n  justify-content: center\n  flex: 1\n"])));
var BusinessName = themed(Title1)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});