import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import React from "react";
var __jsx = React.createElement;
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import Localize from '~/common/Localize';
import { selectIsMobileSidebarOpen, setIsMobileSidebarOpen } from '~/common/slices/app.slice';
import { selectIsUserLoggedIn } from '~/common/slices/user.slice';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import useLoyaltySettings from '~/screens/loyalty/useLoyaltySettings';
import { Body2, Container, Theme, themed, useAppTheme } from '~/styles/Theme';
export default function MobileSidebar(_ref) {
  var isLandingScreen = _ref.isLandingScreen;
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var theme = useAppTheme();
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var dispatch = useDispatch();
  var isMobileSidebarOpen = useSelector(selectIsMobileSidebarOpen);
  var _useLoyaltySettings = useLoyaltySettings(),
    showLoyaltyTab = _useLoyaltySettings.showLoyaltyTab;
  function closeSidebar() {
    dispatch(setIsMobileSidebarOpen(false));
  }
  var navigateTo = function navigateTo(path, queries) {
    push(getRouteUrl(path, queries));
    closeSidebar();
    return;
  };
  if (!isMobileSidebarOpen) {
    return null;
  }
  return __jsx(View, {
    testID: "mobileSidebar"
  }, __jsx(Overlay, {
    onPress: closeSidebar,
    testID: "sidebarOverlay"
  }), __jsx(SidebarContainer, {
    testID: "mobileSidebarMenu"
  }, __jsx(CloseBtn, {
    onPress: closeSidebar,
    testID: "closeSidebarButton"
  }, __jsx(Icon, {
    name: "x",
    color: theme.colors.main,
    size: 24
  })), isLandingScreen ? __jsx(HomePageLinkContainer, {
    onPress: function onPress() {
      closeSidebar();
      push(getRouteUrl(RouteName.Home));
    },
    testID: "visitWebsiteButton"
  }, __jsx(Body2, null, Localize('header.goToWebsite')), __jsx(Icon, {
    style: {
      flex: 1 / 5,
      textAlign: 'center',
      marginLeft: 8
    },
    name: "arrow-up-right",
    color: "#282F3C",
    size: 24
  })) : __jsx(View, {
    testID: "sidebarMenu"
  }, __jsx(SidebarNavLink, {
    iconName: "home",
    navLinkText: "header.home",
    onPress: function onPress() {
      return navigateTo(RouteName.Home);
    },
    testID: "sidebarHomeLink"
  }), __jsx(SidebarNavLink, {
    iconName: "calendar",
    navLinkText: "header.rebookAnAppointment",
    onPress: function onPress() {
      isUserLoggedIn ? navigateTo(RouteName.Appointments) : navigateTo(RouteName.Login, {
        onLogin: RouteName.Appointments
      });
    },
    testID: "sidebarAppointmentsLink"
  }), __jsx(SidebarNavLink, {
    iconName: "users",
    navLinkText: "header.aboutUs",
    onPress: function onPress() {
      return navigateTo(RouteName.AboutUs);
    },
    testID: "sidebarAboutUsLink"
  }), showLoyaltyTab && __jsx(SidebarNavLink, {
    iconName: "gift",
    navLinkText: "header.loyalty",
    onPress: function onPress() {
      return navigateTo(RouteName.Loyalty);
    },
    testID: "sidebarLoyalty"
  }), __jsx(SidebarNavLink, {
    iconName: "user",
    navLinkText: "header.account",
    onPress: function onPress() {
      return navigateTo(RouteName.Account);
    },
    testID: "sidebarAccountLink"
  }))));
}
var SidebarNavLink = function SidebarNavLink(_ref2) {
  var iconName = _ref2.iconName,
    navLinkText = _ref2.navLinkText,
    onPress = _ref2.onPress,
    testID = _ref2.testID;
  var theme = useAppTheme();
  return __jsx(SidebarLink, {
    onPress: onPress,
    testID: testID
  }, __jsx(LinkItem, null, __jsx(Icon, {
    name: iconName,
    color: theme.colors.neutral6,
    size: 16
  }), __jsx(NavLinkText, null, Localize(navLinkText))), __jsx(Icon, {
    name: "chevron-right",
    color: theme.colors.neutral4,
    size: 24
  }));
};
var Overlay = themed(Pressable)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 4;\n"])));
var SidebarContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex: 1;\n  height: 100%;\n  width: 80%;\n  max-width: 400px;\n  position: fixed;\n  z-index: 5;\n  top: 0;\n  left: 0;\n  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);\n  transition: 0.6s;\n  padding: 16px;\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-width: 1px;\n  border-style: solid;\n  ", "\n  ", "\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral7);
}, function (props) {
  return "background: ".concat(Theme(props).colors.neutral);
});
var SidebarLink = themed(Pressable)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 8px 16px;\n  justify-content: space-between;\n  align-items: center;\n  align-self: stretch;\n  border-radius: 8px;\n  border-width: 1px;\n  border-style: solid;\n  ", "\n  margin-bottom: 8px;\n  flex-direction: row;\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.neutral2);
});
var LinkItem = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n"])));
var CloseBtn = themed(Pressable)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin-bottom: 16px;\n"])));
var NavLinkText = themed(Body2)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  margin-left: 8px;\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});
var HomePageLinkContainer = themed(Pressable)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n  align-items: center;\n  flex-direction: row;\n"])));